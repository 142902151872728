import { styled } from "@mui/material/styles";
import LoadingButton from '@mui/lab/LoadingButton';

export const WhiteBgButtonGreenBorder = styled(LoadingButton)((props) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 'normal',
  alignItems: "center",
  textAlign: "center",
  color: "#078167",
  backgroundColor: "#FFFFFF",
  borderRadius: 6,
  borderColor: "#078167",
  borderStyle: "solid",
  borderWidth: 1,
  height: 48,
  lineHeight: 1,
  '&:hover': {
    backgroundColor: '#ffffff',
    borderColor: "#078167",
    borderStyle: "solid",
    borderWidth: 1,
  }
}));