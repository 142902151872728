import { ButtonBase, Modal } from '@mui/material';
import React from 'react';
import CloseIconGray from '../../images/svg/close_gray';
import Styles from './installPWAIOS.module.css';
import IOSShareIcon from '../../images/svg/ios-share-icon';
import FerretFavicon from '../../images/svg/ferret-favicon';

const InstallPWAIOS = ({ setShowInstallPWAIOS }) => {

  return (
    <Modal open={true} className={Styles.modal}>
      <div className={Styles.container}>
        <header>
          <div className={Styles.btnClose}>
            <ButtonBase onClick={() => { setShowInstallPWAIOS(false) }}>
              <CloseIconGray className={Styles.iconClose} />
            </ButtonBase>
          </div>

          <div className={Styles.header}>
            <FerretFavicon className={Styles.logo} />
            <h2 className={Styles.title}>How to Install the App</h2>
          </div>


        </header>
        <main className={Styles.content}>
          <span className={Styles.msg}>
            This is a web app that you can save on your phone, and it works like any other app you have downloaded from the app store; no installation required. Follow the steps bleow to save it to your phone, to access it anytime.
          </span>
          <ol>
            <li className={Styles.listItem}>
              <span className={Styles.iconListItem}>Tap On <strong style={{ marginLeft: '4px' }}>Share Icon</strong> <IOSShareIcon className={Styles.listIcon} /></span>
            </li>
            <li className={Styles.listItem}>
              Select <strong className={Styles.addToHomeScreen}>Add to Home Screen</strong>
            </li>
          </ol>
        </main>
      </div>
    </Modal>
  )
}

export default InstallPWAIOS