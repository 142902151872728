import React, { useEffect, useState, useContext, useRef } from "react";
import { useLazyQuery } from "react-apollo";
import { GET_CONFIGURATION } from "../../../queries/queries";
import createStyle from "./styles";
import Attributes from "../../../components/attributes";
import { mockContent, mockAttributes, tracers } from "./mockValues";
import truncateText from "../../../utils/formatters/truncateText";
import NewsContentDispatcher from "../../../components/newsContentDispatcher/newsContentDispatcher";
import ArticleParts from "../articleParts";
// import RelevancyQuestion from '../relevancyQuestion';
import Illustration from "../../../images/post-article-relevancy-bot-logo.png";
import { extractRootDomain } from "../../../utils/getHostName";
import ZoomAa from "../../../images/svg/zoom-aa";
import TracersView from "./tracersView";
import AssetsView from "./assetsView";
import RecordUnavailable from "./recordUnavailable/recordUnavailable";
import {
  checkParbFunctionVal,
  filterAttributeResult,
} from "../../../utils/utils";
import { VerificationTypeView } from "../../../components/articleCard/articleCard";
import {
  AppBar,
  ButtonBase,
  ButtonGroup,
  Button,
  Toolbar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BackWhiteIcon } from "../../../images/svg/back_white";
import { UserContext } from "../../../contexts/userContext";
import RelevancyQuestion from "../../../components/relevancyQuestion/relevancyQuestion";
import Styles from "./article.module.css";
import Variables from "../../../utils/Variables";

const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

const SCREENHEIGHT = Math.round(windowHeight);

const sectionTypes = {
  news: "News",
  attributes: "Attributes",
};
let zoomOffset = 2; // change here for one step zoom values
let maxZoom = 4; // change here for max Zoom values addition

const Article = (props) => {
  var props = props;

  const navigate = useNavigate();
  const { state } = useLocation();

  if (Object.keys(props).length === 0) {
    props = state;
  }

  const isPDF = props.isPDF ?? false;
  const isPDFSummaryExpanded = props.isPDFSummaryExpanded ?? false;
  const sectionPFDIndex = props.sectionPFDIndex ?? 0;

  let initialArticle = props.article ?? {};
  const newsItem = props.newsItem ?? {};
  const urlVailidaterData = props.urlVailidaterData ?? undefined;
  const isRecent = props.isRecent ?? false;
  const selectedDocument = props.selectedDocument;
  const selectedItem = props.selectedItem ?? {};
  const recentSearchId = props.recentSearchId ?? undefined;
  const articleSource = props.articleSource ?? "FEED";
  const searchTerm = props.searchTerm ?? undefined;
  const savedContactID = props.savedContactID ?? undefined;
  const contactData = props.contactData ?? undefined;
  const setShowLoader = props.setShowLoader ?? undefined;
  const openFilter = props.openFilter ?? undefined;
  const dontShow = props.dontShow ?? undefined;
  const displayPostArticle = props.displayPostArticle ?? false;
  const attributesVal = [];
  const attributesOriginal = [];
  const matchedAttributesOriginal = [];
  const type = props.type ?? "";
  const category =
    newsItem?.parentCategory?.toUpperCase() ??
    newsItem?.category?.toUpperCase();
  const isTracers = newsItem.tracers;
  const setRelevantArticle = props.setRelevantArticle;
  const selectRemoveNotification = props?.selectRemoveNotification;
  const setHeaderTags = props.setHeaderTags ?? undefined;
  const headerTags = props.headerTags ?? undefined;
  const setSaveRelevancyData = props.setSaveRelevancyData ?? undefined;
  const saveRelevancyData = props.saveRelevancyData ?? undefined;
  const setDeleteRelevancyData = props.setDeleteRelevancyData ?? undefined;
  const deleteRelevancyData = props.deleteRelevancyData ?? undefined;
  const potentialMatchId = props.potentialMatchId ?? undefined;

  const { user, setUser } = useContext(UserContext);
  const setShowHeader = props.setShowHeader ?? undefined;
  const setOpenArticleDetail = props.setOpenArticleDetail ?? undefined;
  const setArticleDetails = props.setArticleDetails ?? undefined;
  const articleSummary = props.articleSummary ?? undefined;
  const showArticleSummary = props.showArticleSummary ?? false;
  const setIsArticleOpened = props.setIsArticleOpened ?? undefined;

  useEffect(() => {
    setIsSummaryExpanded(isPDFSummaryExpanded);
  }, [isPDFSummaryExpanded]);

  useEffect(() => {
    setSectionIndex(sectionPFDIndex);
  }, [sectionPFDIndex]);

  let onBackPressNavigation;
  if (props.onBackPressNavigation)
    onBackPressNavigation = props.onBackPressNavigation;

  const searchSettings = props.searchSettings ?? props.searchSettings ?? {};
  initialArticle = {
    ...initialArticle,
    selectedDocument: selectedDocument,
    content: mockContent,
    attributes: attributesVal,
  };
  const mainParantScrollRef = props.mainParantScrollRef;

  const [zoomSize, setZoom] = useState(0);
  const [article, setArticle] = useState(props.article);
  const setAttributes = (attributes) =>
    setArticle({ ...article, attributes: attributes });
  const style = createStyle(newsItem.riskCategory, zoomSize, maxZoom);
  const [sectionIndex, setSectionIndex] = useState(sectionPFDIndex);
  const [isHigherThanScreen, setIsHigherThanScreen] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(
    isPDFSummaryExpanded
  );
  const [isTitleFixed, setIsTitleFixed] = useState(false);
  const [titleHight, setTitleHeight] = useState(0);
  const [headingHeight, setHeadingHeight] = useState(0);
  const [buttonGroupHeight, setButtonGroupHeight] = useState(0);
  const [scrollViewHeight, setScrollViewHeight] = useState(0);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isZoomVisible, setZoomVisible] = useState(false);
  const [divBottomHeight, setDivBottomHeight] = useState(1000);
  const [showButtonGroupView, setShowButtonGroupView] = useState(false);
  const [
    displayPostArticleRelevanceBot,
    setDisplayPostArticleRelevanceBot,
  ] = useState();
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [isWebViewError, setIsWebViewError] = useState(false);

  const [modalVisible, setModalVisible] = useState({
    preArticleRelevant: false,
    postArticleRelevant: false,
  });

  const [scrollPosition, setScrollPosition] = useState(0);

  for (let i = 0; i < props.newsItem.attributes?.length; i++) {
    var arrVal = {
      groupName: props.newsItem.attributes[i].key,
      attributes: [],
    };
    var mArrVal = {
      groupName: props.newsItem.attributes[i].key,
      attributes: [],
    };
    for (let j = 0; j < props.newsItem.attributes[i].value?.length; j++) {
      arrVal.attributes.push({
        label: props.newsItem.attributes[i].value[j],
        status: false,
        type: "radio_button",
      });
      mArrVal.attributes.push({
        label: props.newsItem.attributes[i].value[j],
        status: false,
        type: "radio_button",
      });
    }
    if (mArrVal?.attributes?.length) attributesOriginal.push(mArrVal);
    // arrVal.attributes.push({
    //   "label": 'I don\'t know',
    //   "status": false,
    //   "type": 'dont'
    // })
    arrVal.attributes.push({
      label: "Other",
      status: false,
      value: "",
      type: "textbox",
    });
    attributesVal.push(arrVal);
  }

  for (let i = 0; i < props.newsItem.matchedAttributes?.length; i++) {
    var arrVal = {
      groupName: props.newsItem.matchedAttributes[i].key,
      attributes: [],
    };
    var mArrVal = {
      groupName: props.newsItem.matchedAttributes[i].key,
      attributes: [],
    };
    for (
      let j = 0;
      j < props.newsItem.matchedAttributes[i].value?.length;
      j++
    ) {
      arrVal.attributes.push({
        label: props.newsItem.matchedAttributes[i].value[j],
        status: false,
        type: "radio_button",
      });
      mArrVal.attributes.push({
        label: props.newsItem.matchedAttributes[i].value[j],
        status: false,
        type: "radio_button",
      });
    }
    if (mArrVal?.attributes?.length) matchedAttributesOriginal.push(mArrVal);
    // arrVal.attributes.push({
    //   "label": 'I don\'t know',
    //   "status": false,
    //   "type": 'dont'
    // })
  }

  const [getConfiguration, { loading, error, data }] = useLazyQuery(
    GET_CONFIGURATION
  );

  useEffect(() => {
    getConfiguration();
  }, []);

  useEffect(() => {
    if (data && !loading) {
      let parbFuncationalValue = checkParbFunctionVal(
        data,
        loading,
        newsItem,
        isRecent
      );
      setDisplayPostArticleRelevanceBot(parbFuncationalValue);
    }
  }, [data, loading]);

  useEffect(() => {
    if (displayPostArticle) {
      setDisplayPostArticleRelevanceBot(false);
    }
  }, [displayPostArticle]);

  const backButton = (isSaved = false) => {
    if (onBackPressNavigation) {
      navigate(-1, { replace: true });
      navigate(onBackPressNavigation);
    } else goBack();
    selectedItem.displayPostArticleRelevanceBot = isSaved;
  };

  const onRelevancyClose = (isSaved = false) => {
    selectedItem.displayPostArticleRelevanceBot = isSaved;
    if (isSaved) setDisplayPostArticleRelevanceBot(false);
  };

  const titleDivRef = useRef(null);
  const topDiv = useRef(null);

  const setIsSummaryExpandedHandler = (isExpanded, focusToHighLight = 0) => {
    setTimeout(() => {
      if (mainParantScrollRef) {
        mainParantScrollRef.current?.scrollTo(0, scrollPosition);
      }
    }, 200);
    setIsSummaryExpanded(isExpanded);
  };

  useEffect(() => {
    setIsSummaryExpandedHandler(isSummaryExpanded);
  }, [scrollPosition]);

  let topHeight = headingHeight - titleHight;
  const screenHeight = "50vh";

  useEffect(() => {
    let topHeightOfDiv = topDiv?.current?.clientHeight;
    let bottomHeight = windowHeight - topHeightOfDiv;
    setDivBottomHeight(bottomHeight);
  }, []);

  const sections = [
    {
      title: sectionTypes.news,
      content:
        selectedDocument?.details?.url ||
        article?.pageURL ||
        article?.itemtype == "Unicourt" ? (
          <NewsContentDispatcher
            isWeb={true}
            newsItem={newsItem}
            content={article}
            screenHeight={screenHeight}
            setIsWebViewError={(errorFlag) =>
              setIsWebViewErrorHandler(errorFlag)
            }
            setIsSummaryExpanded={setIsSummaryExpandedHandler}
            isSummaryExpanded={isSummaryExpanded}
            isExpandable={isExpandable}
            removeIframe={modalVisible?.postArticleRelevant}
            urlVailidaterData={urlVailidaterData}
            recentSearchId={recentSearchId}
            contactDataID={newsItem.contactID ?? savedContactID}
            displayPostArticleRelevanceBot={displayPostArticleRelevanceBot}
            potentialMatchId={
              newsItem?.potentialMatchId ??
              potentialMatchId ??
              Variables.potentialMatchId
            }
            setIsArticleOpened={setIsArticleOpened}
          />
        ) : (
          <RecordUnavailable
            screenHeight={screenHeight}
            fullText={article?.fulltext}
            setIsSummaryExpanded={setIsSummaryExpandedHandler}
            isSummaryExpanded={isSummaryExpanded}
            isExpandable={isExpandable}
            setIsArticleOpened={setIsArticleOpened}
          />
        ),
    },
    {
      title: sectionTypes.attributes,
      content: (
        <div style={style.attributesContainer}>
          {attributesOriginal && attributesOriginal.length > 0 ? (
            <Attributes
              theme={"light"}
              attributes={attributesOriginal}
              from={"article"}
              newsItem={newsItem}
              matchedAttributesOriginal={matchedAttributesOriginal}
              isFromArticle={true}
            />
          ) : (
            <div style={style.noAttributeMainView}>
              <div style={style.noAttributeImgDiv}>
                <img src={Illustration} style={style.noAttributeImg} />
              </div>
              <span style={style.noAttributeTitle}>
                Hmmm… there are no attributes in this article yet
              </span>
              <span style={style.noAttributeDesc}>
                Attributes for this article will appear here once they become
                available.
              </span>
              <p className={Styles.bottomLable}>
                {newsItem?.articleId}-{newsItem?.sourceIdentifier}-
                {newsItem?.verifiedBy}-{newsItem?.matchingLogic}
              </p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const goBack = () => {
    // setOpenArticleDetail?.({})
    setArticleDetails?.(false);
    setShowHeader?.(true);
  };

  const onArticleAccept = () => {
    setIsSummaryExpanded(false);
    if (displayPostArticleRelevanceBot == true) {
      setModalVisible({
        ...modalVisible,
        postArticleRelevant: true,
      });
    } else {
      backButton(true);
    }
  };
  const underlineArticleUrl = article.pageURL ? "underline" : "none";
  let articlePageURL = article.pageURL;
  if (
    (article.pageURL == null || article?.pageURL?.length == 0) &&
    article?.selectedDocument?.details
  ) {
    articlePageURL = article.selectedDocument?.details?.url;
  }
  const articleUrl = truncateText(
    articlePageURL && articlePageURL?.length > 0
      ? articlePageURL
      : "Article Not Online",
    35
  );
  const openArticleUrl = article.pageURL
    ? () => window.open(article.pageURL, "_blank")
    : null;

  const setTitleHightHandler = (newTitleHight) => setTitleHeight(newTitleHight);

  const isTitleInTop = (contentOffset) => {
    const isInTop = contentOffset > (isTracers ? 40 : 44);
    if (isInTop && !isTitleFixed) setIsTitleFixed(true);
    if (!isInTop && isTitleFixed) setIsTitleFixed(false);
  };

  const isButtonGroupView = (contentOffset) => {
    const isComplete = contentOffset > headingHeight - 50;
    if (isComplete && !showButtonGroupView) setShowButtonGroupView(true);
    if (!isComplete && showButtonGroupView) setShowButtonGroupView(false);
  };

  useEffect(() => {
    setHeadingHeight(topDiv?.current?.clientHeight);
  }, []);

  const setIsWebViewErrorHandler = (newErrorValue) =>
    setIsWebViewError(newErrorValue);

  useEffect(() => setIsScrollEnabled(isHigherThanScreen && isSummaryExpanded), [
    isHigherThanScreen,
    isSummaryExpanded,
  ]);

  let articleSrc;
  if (article?.pageURL) articleSrc = extractRootDomain(article.pageURL);
  let sourceName = articleSrc?.srcName || "";
  let isHyperLink = false;
  if (articleSrc?.srcName.toLowerCase() === "crunchbase") {
    sourceName = `Powered by ${articleSrc?.srcName}`;
    isHyperLink = true;
  }

  useEffect(() => {
    const height = topDiv?.current?.clientHeight;
    setScrollViewHeight(height);
  }, []);

  return (
    <div id="article-content-div">
      <div ref={topDiv} style={{ ...style.mainContainer }}>
        <ArticleParts
          isPDF={isPDF}
          isTracers={isTracers}
          zoomSize={zoomSize}
          article={props.article}
          articleType={newsItem.articleType ?? newsItem.category}
          fullName={newsItem.highlightKey ?? newsItem.fullName}
          riskCategory={newsItem.riskCategory}
          setIsHigherThanScreen={setIsHigherThanScreen}
          setIsSummaryExpanded={setIsSummaryExpandedHandler}
          isSummaryExpanded={isSummaryExpanded}
          screenHeight={SCREENHEIGHT}
          isTitleFixed={isTitleFixed}
          setTitleHeight={setTitleHightHandler}
          isWebViewError={isWebViewError}
          setRelevantArticle={setRelevantArticle}
          selectRemoveNotification={selectRemoveNotification}
          backButton={backButton}
          newsItem={newsItem}
          onArticleAccept={onArticleAccept}
          displayRelevancyArticle={displayPostArticleRelevanceBot == true}
          setIsExpandable={setIsExpandable}
          isExpandable={isExpandable}
          scrollPosition={scrollPosition}
          setScrollPosition={setScrollPosition}
          openFilter={openFilter}
          dontShow={dontShow}
          articleSummary={articleSummary}
          showArticleSummary={showArticleSummary}
        />
        {isHyperLink ? (
          <div style={style.articleSource}>
            <img
              src={`https://unavatar.io/${articleSrc?.domain}`}
              style={style.articleSrcImg}
            />
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (isHyperLink)
                  window.location.href = "http://www.crunchbase.com";
              }}
              style={style.articleCategory}
              numberOfLines={1}
            >
              {sourceName}
            </span>
          </div>
        ) : null}
        <RelevancyQuestion
          recentSearchId={recentSearchId}
          searchTerm={searchTerm ?? newsItem.fullName}
          articleSource={articleSource}
          refId={newsItem.id ? newsItem.id : newsItem.articleId}
          articleCount={props?.route?.params?.articleCount}
          articleRelevacnyData={props?.route?.params?.articleRelevacnyData}
          articleId={newsItem.articleId}
          contactId={newsItem.contactID ?? savedContactID}
          attributes={article?.attributes}
          setAttributes={setAttributes}
          fullName={newsItem.fullName}
          searchSettings={searchSettings}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          backButton={onRelevancyClose}
          contactData={contactData}
          setShowLoader={setShowLoader}
          setHeaderTags={setHeaderTags}
          headerTags={headerTags}
          setSaveRelevancyData={setSaveRelevancyData}
          saveRelevancyData={saveRelevancyData}
          deleteRelevancyData={deleteRelevancyData}
          setDeleteRelevancyData={setDeleteRelevancyData}
          setRefreshList={props.setRefreshList}
          goBack={goBack}
          potentialMatchId={
            newsItem?.potentialMatchId ??
            potentialMatchId ??
            Variables.potentialMatchId
          }
        />
        {!isTracers && (
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            className={Styles.segmentedControlContainer}
          >
            <Button
              className={Styles.segmentedControlChild}
              style={
                sectionIndex == 0
                  ? style.segmentedControlSelectedButton
                  : style.segmentedControlButton
              }
              onClick={() => setSectionIndex(0)}
            >
              <span
                style={
                  sectionIndex == 0
                    ? style.segmentedControlSelectedText
                    : style.segmentedControlText
                }
              >
                {sections[0].title}
              </span>
            </Button>
            <Button
              className={Styles.segmentedControlChild}
              style={
                sectionIndex == 1
                  ? style.segmentedControlSelectedButton
                  : style.segmentedControlButton
              }
              onClick={() => setSectionIndex(1)}
            >
              <span
                style={
                  sectionIndex == 1
                    ? style.segmentedControlSelectedText
                    : style.segmentedControlText
                }
              >
                {sections[1].title}
              </span>
            </Button>
          </ButtonGroup>
        )}
      </div>
      {isTracers &&
        (newsItem?.category == "Assets" ? (
          <AssetsView
            style={style}
            data={newsItem.tracerData}
            newsItem={newsItem}
          />
        ) : (
          <TracersView
            style={style}
            data={newsItem.tracerData}
            newsItem={newsItem}
          />
        ))}
      {!isTracers && sections[sectionIndex].content}
    </div>
  );
};

export default Article;
