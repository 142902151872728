import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLazyQuery } from 'react-apollo';
import { GET_CONFIGURATION } from '../../queries/queries';
import Attributes from '../attributes';
import { mockContent, mockAttributes, tracers } from './mockValues';
import truncateText from '../../utils/formatters/truncateText';
import NewsContentDispatcher from '../newsContentDispatcher/newsContentDispatcher';
import ArticleParts from '../articleParts';
// import RelevancyQuestion from '../relevancyQuestion';
import Illustration from '../../images/post-article-relevancy-bot-logo.png'
import { extractRootDomain } from '../../utils/getHostName';
import Variables from '../../utils/Variables';
import ZoomAa from '../../images/svg/zoom-aa'
import TracersView from './tracersView'
import RecordUnavailable from './recordUnavailable/recordUnavailable';
import { capitalizeCase, checkParbFunctionVal, filterAttributeResult } from '../../utils/utils';
import { VerificationTypeView } from '../articleCard/articleCard';
import { AppBar, ButtonBase, ButtonGroup, Button, Toolbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackWhiteIcon } from '../../images/svg/back_white';
import { DefaultAvatar } from '../../images/svg/default-avatar';
import { UserContext } from '../../contexts/userContext';
import RelevancyQuestion from '../relevancyQuestion/relevancyQuestion';
import Styles from './article.module.css'
import PDFDownload from '../../images/svg/pdf-download';
import Download from '../../images/svg/download';
import { searchResultStore } from '../../store/searchResultStore';

const { innerWidth: windowWidth, innerHeight: windowHeight } = window

const SCREENHEIGHT = Math.round(windowHeight);

const sectionTypes = {
  news: 'News',
  attributes: 'Attributes',
};
let zoomOffset = 2 // change here for one step zoom values
let maxZoom = 4 // change here for max Zoom values addition

const Article = (props) => {
  var props = props

  const navigate = useNavigate();
  const { state } = useLocation()

  if (Object.keys(props).length === 0) {
    props = state
  }

  let initialArticle = props.article ?? props.article ?? {};
  const newsItem = props.newsItem ?? props.newsItem ?? {};
  const isRecent = props.isRecent ?? props.isRecent ?? false;
  const selectedDocument = props.selectedDocument ?? props.selectedDocument;
  const selectedItem = props.selectedItem ?? props.selectedItem ?? {};
  const recentSearchId = props.recentSearchId ?? props.recentSearchId ?? undefined;
  const articleSource = props.articleSource ?? props.articleSource ?? 'FEED';
  const searchTerm = props.searchTerm ?? props.searchTerm ?? undefined;
  const savedContactID = props.savedContactID ?? props.savedContactID ?? undefined;
  const contactData = props.contactData ?? props.contactData ?? undefined;
  const setShowLoader = props.setShowLoader ?? props.setShowLoader ?? undefined;
  const openFilter = props.openFilter ?? props.openFilter ?? undefined;
  const dontShow = props.dontShow ?? props.dontShow ?? undefined;
  const attributesVal = []
  const attributesOriginal = []
  const matchedAttributesOriginal = []
  const type = props.type ?? props.type ?? '';
  const category = newsItem?.parentCategory?.toUpperCase() ?? newsItem?.category?.toUpperCase();
  const isTracers = newsItem.tracers;
  const setRelevantArticle = props.setRelevantArticle
  const selectRemoveNotification = props?.route?.params?.selectRemoveNotification
  const setHeaderTags = props.setHeaderTags ?? props.setHeaderTags ?? undefined;
  const headerTags = props.headerTags ?? props.headerTags ?? undefined;
  const setSaveRelevancyData = props.setSaveRelevancyData ?? props.setSaveRelevancyData ?? undefined;
  const saveRelevancyData = props.saveRelevancyData ?? props.saveRelevancyData ?? undefined;
  const setDeleteRelevancyData = props.setDeleteRelevancyData ?? props.setDeleteRelevancyData ?? undefined;
  const deleteRelevancyData = props.deleteRelevancyData ?? props.deleteRelevancyData ?? undefined;

  const { user, setUser } = useContext(UserContext);
  const feed = props.feed ?? {}
  const setShowHeader = props.setShowHeader ?? undefined;
  const setOpenArticleDetail = props.setOpenArticleDetail ?? undefined;
  const setArticleDetails = props.setArticleDetails ?? undefined;

  const setPDFIsSummaryExpanded = props.setPDFIsSummaryExpanded ?? undefined;
  const setPDFSectionIndex = props.setPDFSectionIndex ?? undefined;

  const openMapDashboard = searchResultStore((state) => state.openMapDashboard);

  let onBackPressNavigation;
  if (props.onBackPressNavigation) onBackPressNavigation = props.onBackPressNavigation;

  const searchSettings =
    props.searchSettings ?? props.searchSettings ?? {};
  initialArticle = {
    ...initialArticle,
    selectedDocument: selectedDocument,
    content: mockContent,
    attributes: attributesVal,
  };
  const handleGeneratePDF = props.handleGeneratePDF ? props.handleGeneratePDF : ()=>{}

  const [zoomSize, setZoom] = useState(0);
  const [article, setArticle] = useState(initialArticle);
  const setAttributes = attributes =>
    setArticle({ ...article, attributes: attributes });
  // const style = createStyle(newsItem.riskCategory, zoomSize, maxZoom);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [isHigherThanScreen, setIsHigherThanScreen] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const [isTitleFixed, setIsTitleFixed] = useState(false);
  const [titleHight, setTitleHeight] = useState(0);
  const [headingHeight, setHeadingHeight] = useState(0);
  const [buttonGroupHeight, setButtonGroupHeight] = useState(0);
  const [scrollViewHeight, setScrollViewHeight] = useState(0);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isZoomVisible, setZoomVisible] = useState(false);
  const [divBottomHeight, setDivBottomHeight] = useState(1000);
  const [showButtonGroupView, setShowButtonGroupView] = useState(false);
  const [displayPostArticleRelevanceBot, setDisplayPostArticleRelevanceBot] = useState()
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [isWebViewError, setIsWebViewError] = useState(false);

  const [modalVisible, setModalVisible] = useState({
    preArticleRelevant: false,
    postArticleRelevant: false,
  });

  useEffect(() => {
    if (setPDFIsSummaryExpanded)
      setPDFIsSummaryExpanded(isSummaryExpanded);
  }, [isSummaryExpanded]);

  useEffect(() => {
    if (setPDFSectionIndex)
      setPDFSectionIndex(sectionIndex);
  }, [sectionIndex]);

  const [scrollPosition, setScrollPosition] = useState(0);

  for (let i = 0; i < props.newsItem.attributes?.length; i++) {
    var arrVal = {
      "groupName": props.newsItem.attributes[i].key,
      "attributes": []
    }
    var mArrVal = {
      "groupName": props.newsItem.attributes[i].key,
      "attributes": []
    }
    for (let j = 0; j < props.newsItem.attributes[i].value?.length; j++) {
      arrVal.attributes.push({
        "label": props.newsItem.attributes[i].value[j],
        "status": false,
        "type": 'radio_button'
      })
      mArrVal.attributes.push({
        "label": props.newsItem.attributes[i].value[j],
        "status": false,
        "type": 'radio_button'
      })
    }
    if (mArrVal?.attributes?.length)
      attributesOriginal.push(mArrVal)
    // arrVal.attributes.push({
    //   "label": 'I don\'t know',
    //   "status": false,
    //   "type": 'dont'
    // })
    arrVal.attributes.push({
      "label": 'Other',
      "status": false,
      "value": '',
      "type": 'textbox'
    })
    attributesVal.push(arrVal)
  }

  for (let i = 0; i < props.newsItem.matchedAttributes?.length; i++) {
    var arrVal = {
      "groupName": props.newsItem.matchedAttributes[i].key,
      "attributes": []
    }
    var mArrVal = {
      "groupName": props.newsItem.matchedAttributes[i].key,
      "attributes": []
    }
    for (let j = 0; j < props.newsItem.matchedAttributes[i].value?.length; j++) {
      arrVal.attributes.push({
        "label": props.newsItem.matchedAttributes[i].value[j],
        "status": false,
        "type": 'radio_button'
      })
      mArrVal.attributes.push({
        "label": props.newsItem.matchedAttributes[i].value[j],
        "status": false,
        "type": 'radio_button'
      })
    }
    if (mArrVal?.attributes?.length)
      matchedAttributesOriginal.push(mArrVal)
    // arrVal.attributes.push({
    //   "label": 'I don\'t know',
    //   "status": false,
    //   "type": 'dont'
    // })
  }

  const [getConfiguration, { loading, error, data }] = useLazyQuery(GET_CONFIGURATION);

  useEffect(() => {
    getConfiguration()
  }, [])


  useEffect(() => {
    if (data && !loading) {
      let parbFuncationalValue = checkParbFunctionVal(data, loading, newsItem, isRecent)
      setDisplayPostArticleRelevanceBot(parbFuncationalValue)
    }
  }, [data, loading])

  const backButton = (isSaved = false) => {
    if (onBackPressNavigation) {
      navigate((-1), { replace: true });
      navigate(onBackPressNavigation)
    }
    else
      goBack()
    selectedItem.displayPostArticleRelevanceBot = isSaved
  }

  const onRelevancyClose = (isSaved = false) => {
    selectedItem.displayPostArticleRelevanceBot = isSaved
    if (isSaved)
      setDisplayPostArticleRelevanceBot(false)
  }

  const mainScrollRef = useRef(null);
  const titleDivRef = useRef(null);
  const topDiv = useRef(null);

  const setIsSummaryExpandedHandler = (isExpanded, focusToHighLight = 0) => {
    setTimeout(() => {
      mainScrollRef.current?.scrollTo(0, scrollPosition)
    }, 200)
    setIsSummaryExpanded(isExpanded);
  };

  useEffect(() => {
    setIsSummaryExpandedHandler(isSummaryExpanded)
  }, [scrollPosition])

  let topHeight = headingHeight - titleHight
  const screenHeight =
    topHeight < scrollViewHeight
      ? Number(scrollViewHeight - topHeight)
      : scrollViewHeight

  useEffect(() => {
    let topHeightOfDiv = topDiv?.current?.clientHeight
    let bottomHeight = windowHeight - topHeightOfDiv
    setDivBottomHeight(bottomHeight)
  }, [])

  const sections = [
    {
      title: sectionTypes.news,
      content: <div className={Styles.attributesContainer}>
        {
          (selectedDocument?.details?.url || article?.pageURL || article?.itemtype == "Unicourt") ? (
            <NewsContentDispatcher
              content={article}
              newsItem={props.newsItem }
              screenHeight={screenHeight}
              setIsWebViewError={(errorFlag) => setIsWebViewErrorHandler(errorFlag)}
              setIsSummaryExpanded={setIsSummaryExpandedHandler}
              isSummaryExpanded={isSummaryExpanded}
              isExpandable={isExpandable}
              removeIframe={modalVisible?.postArticleRelevant}
              recentSearchId={recentSearchId}
              contactDataID={newsItem.contactID ?? savedContactID}
              displayPostArticleRelevanceBot={displayPostArticleRelevanceBot}
              potentialMatchId={newsItem?.potentialMatchId ?? feed?.potentialMatchId ?? Variables.potentialMatchId}
            />
          ) : (
            <RecordUnavailable
              screenHeight={screenHeight}
              fullText={article?.fulltext}
              setIsSummaryExpanded={setIsSummaryExpandedHandler}
              isSummaryExpanded={isSummaryExpanded}
              isExpandable={isExpandable}
            />
          )
        }
      </div>,
    },
    {
      title: sectionTypes.attributes,
      content: (
        <div className={Styles.attributesContainer}>
          {attributesOriginal && attributesOriginal.length > 0 ? (
            <Attributes
              theme={'light'}
              attributes={attributesOriginal}
              from={'article'}
              newsItem={newsItem}
              matchedAttributesOriginal={matchedAttributesOriginal}
              isFromArticle={true}
            />
          ) : (
            <div className={Styles.noAttributeMainView}>
              <img src={Illustration} className={Styles.noAttributeImg} />
              <span className={Styles.noAttributeTitle}>
                Hmmm… there are no attributes in this article yet
              </span>
              <span className={Styles.noAttributeDesc}>
                Attributes for this article will appear here once they become
                available.
              </span>
              <p style={{ fontSize: 12, color: "#aaaaaa", paddingTop: 20, textAlign: "center" }}>
                {newsItem?.articleId}-{newsItem?.sourceIdentifier}-{newsItem?.verifiedBy}-{newsItem?.matchingLogic}
              </p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const onPressZoom = (isPlus = false) => {
    if (isPlus && zoomSize < maxZoom) {
      setZoom(zoomSize + zoomOffset)
    } else if (!isPlus && zoomSize > 0) {
      setZoom(zoomSize - zoomOffset)
    }
  }

  const goBack = () => {
    setOpenArticleDetail?.({ status: false, data: {} })
    setArticleDetails?.(false)
    setShowHeader?.(true)
    if (!isRecent) {
      navigate(openMapDashboard ? -2 : -1, { replace: true });
    }
  }

  const title = truncateText(newsItem.fullName, 23);
  const header = () => {
    const showProfilePicture = feed.searchMe && user.profilePhoto;
    const headerLeftStyle = Styles.headerLeft;
    return (<AppBar component="nav" position='sticky'>
      <div className={Styles.headerContainer}>
        <div className={Styles.leftHeaderContainer}>
          <ButtonBase
            style={{ marginRight: 8 }}
            onClick={() => {
              if (onBackPressNavigation) {
                navigate(openMapDashboard ? -2 : -1, { replace: true })
              } else {
                goBack()
              }
            }}>
            <BackWhiteIcon />
          </ButtonBase>
          {/* <div>
            {feed?.profilePictureUri || showProfilePicture ? (
              <img
                source={{
                  uri: showProfilePicture
                    ? user?.profilePhoto
                    : user?.profilePictureUri,
                }}
                className={Styles.profilePicture}
              />
            ) : (
              <DefaultAvatar className={Styles.profilePicture} />
            )}
          </div> */}
        </div>
        <span className={Styles.headerLeftFullName}>
          {capitalizeCase(title, true)}
        </span>
        <div style={{flexDirection: 'row', display: 'flex', gap: 12}}>
          <div className={Styles.zoomView}>
            <ButtonBase onClick={handleGeneratePDF}><PDFDownload/> <Download /></ButtonBase>
          </div>
          <div className={Styles.zoomView}>
            <ButtonBase onClick={() => setZoomVisible(!isZoomVisible)} style={{ marginEnd: 20 }}><ZoomAa /></ButtonBase>
          </div>
        </div>
      </div>
    </AppBar>)
  }

  const onArticleAccept = () => {
    setIsSummaryExpanded(false);
    if (displayPostArticleRelevanceBot == true) {
      setModalVisible({
        ...modalVisible,
        postArticleRelevant: true,
      });
    }
    else {
      backButton(true)
    }
  }
  const underlineArticleUrl = article.pageURL ? 'underline' : 'none';
  let articlePageURL = article.pageURL
  if ((article.pageURL == null || article?.pageURL?.length == 0) && article?.selectedDocument?.details) {
    articlePageURL = article.selectedDocument?.details?.url
  }
  const articleUrl = truncateText((articlePageURL && articlePageURL?.length > 0) ? articlePageURL : 'Article Not Online', 35);
  const openArticleUrl = (article.pageURL || articlePageURL)
    ? () => window.open(article.pageURL || articlePageURL, "_blank")
    : null;


  const setTitleHightHandler = newTitleHight => setTitleHeight(newTitleHight);

  const isTitleInTop = contentOffset => {
    const isInTop = contentOffset > (isTracers ? 40 : 44);
    if (isInTop && !isTitleFixed) setIsTitleFixed(true);
    if (!isInTop && isTitleFixed) setIsTitleFixed(false);
  };

  const isButtonGroupView = contentOffset => {
    const isComplete = contentOffset > headingHeight - 50;
    if (isComplete && !showButtonGroupView) setShowButtonGroupView(true);
    if (!isComplete && showButtonGroupView) setShowButtonGroupView(false);
  };

  const onScrollHandler = () => {
    if (mainScrollRef.current) {
      const { scrollTop } = mainScrollRef.current;
      isTitleInTop(scrollTop);
      isButtonGroupView(scrollTop);
    }
  };

  useEffect(() => {
    setHeadingHeight(topDiv?.current?.clientHeight)
  }, [])

  const setIsWebViewErrorHandler = newErrorValue =>
    setIsWebViewError(newErrorValue);

  useEffect(() => setIsScrollEnabled(isHigherThanScreen && isSummaryExpanded), [
    isHigherThanScreen,
    isSummaryExpanded,
  ]);

  let articleSrc;
  if (article?.pageURL) articleSrc = extractRootDomain(article.pageURL);
  let sourceName = articleSrc?.srcName || ''
  let isHyperLink = false;
  if (articleSrc?.srcName.toLowerCase() === 'crunchbase') {
    sourceName = `Powered by ${articleSrc?.srcName}`
    isHyperLink = true
  }

  useEffect(() => {
    const height = mainScrollRef?.current?.clientHeight;
    setScrollViewHeight(height)
  }, [])

  return (
    <div className={Styles.container}>
      {header()}
      {isZoomVisible &&
        <div className={Styles.zoomTopView}>
          <div className={Styles.triangle} />
          <div className={Styles.zoomPopupView}>
            <ButtonBase onClick={() => onPressZoom(false)} disabled={zoomSize <= 0}><span className={`${Styles.zoomTextALess} ${zoomSize <= 0 && Styles.zoomGrey}`}>-A</span></ButtonBase>
            <div className={Styles.board}></div>
            <ButtonBase onClick={() => onPressZoom(true)} disabled={zoomSize >= maxZoom}><span className={`${Styles.zoomTextAPlus} ${zoomSize >= maxZoom && Styles.zoomGrey}`}>+A</span></ButtonBase>
          </div>
        </div>
      }

      <div
        style={{ height: "94%", overflowY: "scroll", display: "flex", flexDirection: "column" }}
        ref={mainScrollRef}
        onScroll={onScrollHandler}>

        {isTitleFixed && (
          <div className={Styles.titleContainer}>
            <span numberOfLines={2} className={Styles.title} style={{ fontSize: 18 + zoomSize }}>
              {article.title}
            </span>
          </div>
        )}
        <div
          ref={topDiv}
          className={Styles.mainContainer}>
          <div className={Styles.matchTypeContainer}>
            {/* <View className={Styles.titleDecorator} /> */}
            {/* {isTracers && <Text className={Styles.categoryText}>{category+' '}</Text>} */}
            {/* <Text className={Styles.matchTypeText}>{newsItem.matchType}</Text> */}
            <VerificationTypeView verificationType={newsItem?.verificationType} textColor={"#C0C7D6"} />
            {!isTracers && <div className={Styles.urlContainer}>
              <span
                className={Styles.urlText}
                style={{
                  textDecorationLine: underlineArticleUrl,
                  fontSize: 13 + zoomSize
                }}
                onClick={openArticleUrl}
              >
                {articleUrl}
              </span>
            </div>}
          </div>
          <ArticleParts
            isTracers={isTracers}
            zoomSize={zoomSize}
            article={article}
            articleType={newsItem.articleType ?? newsItem.category}
            fullName={newsItem.highlightKey ?? newsItem.fullName}
            riskCategory={newsItem.riskCategory}
            setIsHigherThanScreen={setIsHigherThanScreen}
            setIsSummaryExpanded={setIsSummaryExpandedHandler}
            isSummaryExpanded={isSummaryExpanded}
            screenHeight={SCREENHEIGHT}
            isTitleFixed={isTitleFixed}
            setTitleHeight={setTitleHightHandler}
            isWebViewError={isWebViewError}
            setRelevantArticle={setRelevantArticle}
            selectRemoveNotification={selectRemoveNotification}
            backButton={backButton}
            newsItem={newsItem}
            onArticleAccept={onArticleAccept}
            displayRelevancyArticle={displayPostArticleRelevanceBot == true}
            setIsExpandable={setIsExpandable}
            isExpandable={isExpandable}
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
            openFilter={openFilter}
            dontShow={dontShow}
            articleSummary={props.articleSummary}
            setArticleSummary={props.setArticleSummary}
            showArticleSummary={props.showArticleSummary}
            setShowArticleSummary={props.setShowArticleSummary}
            setOpenArticleDetail={props.setOpenArticleDetail}
            openArticleDetail={props.openArticleDetail}
          />
          {isHyperLink ?
            <div className={Styles.articleSource}>
              <img
                src={`https://unavatar.io/${articleSrc?.domain}`}
                className={Styles.articleSrcImg}
              />
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  if (isHyperLink)
                    window.location.href = 'http://www.crunchbase.com'
                }}
                className={Styles.articleCategory} numberOfLines={1}>
                {sourceName}
              </span>
            </div>
            : null}
          <RelevancyQuestion
            recentSearchId={recentSearchId}
            searchTerm={searchTerm ?? newsItem.fullName}
            articleSource={articleSource}
            refId={newsItem.id ? newsItem.id : newsItem.articleId}
            articleCount={props?.route?.params?.articleCount}
            articleRelevacnyData={
              props?.route?.params?.articleRelevacnyData
            }
            articleId={newsItem.articleId}
            contactId={newsItem.contactID ?? savedContactID}
            attributes={article?.attributes}
            setAttributes={setAttributes}
            fullName={newsItem.fullName}
            searchSettings={searchSettings}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            backButton={onRelevancyClose}
            contactData={contactData}
            setShowLoader={setShowLoader}
            setHeaderTags={setHeaderTags}
            headerTags={headerTags}
            setSaveRelevancyData={setSaveRelevancyData}
            saveRelevancyData={saveRelevancyData}
            deleteRelevancyData={deleteRelevancyData}
            setDeleteRelevancyData={setDeleteRelevancyData}
            setRefreshList={props.setRefreshList ?? props?.route?.params?.setRefreshList}
            goBack={goBack}
            potentialMatchId={newsItem?.potentialMatchId ?? feed?.potentialMatchId ?? Variables.potentialMatchId}
          />
          {!isTracers && <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            className={Styles.segmentedControlContainer}
          >
            <Button
              fullWidth
              className={sectionIndex == 0 ? Styles.segmentedControlSelectedButton : Styles.segmentedControlButton}
              onClick={() => setSectionIndex(0)}>
              <span className={sectionIndex == 0 ? Styles.segmentedControlSelectedText : Styles.segmentedControlText} style={{ fontSize: 14 + zoomSize }}>
                {sections[0].title}
              </span>
            </Button>
            <Button
              fullWidth
              className={sectionIndex == 1 ? Styles.segmentedControlSelectedButton : Styles.segmentedControlButton}
              onClick={() => setSectionIndex(1)}>
              <span className={sectionIndex == 1 ? Styles.segmentedControlSelectedText : Styles.segmentedControlText} style={{ fontSize: 14 + zoomSize }}>
                {sections[1].title}
              </span>
            </Button>
          </ButtonGroup>}
        </div>
        {isTracers && <TracersView zoomSize={zoomSize} data={newsItem.tracerData} newsItem={newsItem} />}
        {!isTracers && sections[sectionIndex].content}
      </div>
    </div>
  );
};

export default Article;