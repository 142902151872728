import React, { useState, useEffect } from "react";
import PersonalInfoSummary from "./personalInfoSummary";
import ArticleCategoriesList from "./articleCategoriesList";
import createStyle from "./styles";
import FilterByVerificationType from "../filterByVerificationType/filterByVerificationType";
import Styles from "./profileinfo.module.css";
import DropdownArrowGreen from "../../../images/svg/dropdown-green";
import FerretAILogo from "../../../images/svg/logo-icon-color";
import TypeWriter from "../../../components/PersonInfo/TypeWriter";
import { GENERATE_SUMMARY } from "../../../queries/queries";
import { Box, LinearProgress } from "@mui/material";
import { useLazyQuery } from "react-apollo";

const PersoneInfo = (props) => {
  const { personalSummary, emptyCategories, dataCategories } =
    props?.personalInfo;
  const {
    profileName = "",
    linkedInData,
    feedbackOptions,
    profilePic,
    setProfilePic,
    PDFFerretAISummary,
    PDFFerretAI,
    educationPDFMore,
    experiencePDFMore,
    showPDFOthers,
    showPDFMore,
  } = props;
  const {
    onClick,
    verified,
    setVerified,
    partial,
    setPartial,
    needsReview,
    setNeedsReview,
    processArticleInProgress,
    potentialMatchId,
  } = props;
  const style = createStyle();

  const [dataCat, setDataCat] = useState([]);
  const [emptyDataCat, setEmptyDataCat] = useState([]);
  const [ferretAI, setFerretAI] = useState(false);
  const [ferretAISummary, setFerretAISummary] = useState();

  const [generateSummary, { loading, data, error }] =
    useLazyQuery(GENERATE_SUMMARY);
  const onGetFerretAISummary = () => {
    if (loading) return;
    setFerretAI(!ferretAI);
    if (ferretAISummary == undefined)
      generateSummary({
        variables: {
          uuid: potentialMatchId,
          type: "LINKEDIN",
        },
      });
  };

  useEffect(()=>{
    if(PDFFerretAISummary){
      setFerretAISummary(PDFFerretAISummary)
      console.log('PDFFerretAISummary=====',PDFFerretAISummary)
  }
  },[PDFFerretAISummary]);

  useEffect(()=>{
    if(PDFFerretAI){
      setFerretAI(PDFFerretAI)
      console.log('PDFFerretAI=====',PDFFerretAI)
    }
  },[PDFFerretAI]);

  useEffect(() => {
    if (!loading && data && data?.generateSummary?.summary) {
      let summary = JSON.parse(data.generateSummary.summary);
      setFerretAISummary(summary.summary);
    }
  }, [loading, data]);

  useEffect(() => {
    if (dataCategories && processArticleInProgress) {
      let dataMap = dataCategories.map((item) => item.category)
      let newDataCategory = [...dataCategories.map((item) => ({ ...item, isProcessing: processArticleInProgress.includes(item.category) })),
      ...(processArticleInProgress.filter((item) => !dataMap.includes(item))
        .map((item) => ({ category: item, isProcessing: true, count: 0 })))
        .sort((a, b) => b.isProcessing - a.isProcessing)]
      newDataCategory.sort((a, b) => b.isProcessing - a.isProcessing)
      setDataCat(newDataCategory)

      let newEmptyDataCategory = emptyCategories ? [...emptyCategories].filter((item) => !processArticleInProgress.includes(item.category) && item.category !== "Crunchbase") : []
      setEmptyDataCat(newEmptyDataCategory)
    } else if (dataCategories || emptyCategories) {
      setDataCat(dataCategories ? [...dataCategories] : [])
      setEmptyDataCat(emptyCategories ? [...emptyCategories] : [])
    }
  }, [dataCategories, processArticleInProgress, emptyCategories]);

  return (
    <div  id="content-to-pdf" className={Styles.mainContainer}>
      <div className={Styles.leftView}>
        {dataCat?.length > 0 && (
          <PersonalInfoSummary
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            feedbackOptions={feedbackOptions}
            linkedInData={linkedInData}
            profileName={profileName}
            personalSummary={personalSummary}
            educationPDFMore={educationPDFMore}
            experiencePDFMore={experiencePDFMore}
            showPDFOthers={showPDFOthers}
            showPDFMore={showPDFMore}
          />
        )}
      </div>
      <div className={Styles.rightView}>
        {linkedInData?.url &&
          (linkedInData?.education?.length > 0 ||
            linkedInData?.experience?.length > 0) && (
            <div className={Styles.ferretViewMoreViewCenter}>
              <div
                className={Styles.ferretViewMoreViewCenter2}
                onClick={() =>
                  linkedInData?.url ? onGetFerretAISummary() : null
                }
              >
                <div className={Styles.ferretView}>
                  <FerretAILogo />
                  <span className={Styles.ferretAI}>
                    {"Generate Ferret AI Profile Summary"}
                  </span>
                </div>
                {loading && ferretAI && (
                  <Box
                    sx={{
                      width: 24,
                      "& .MuiLinearProgress-colorPrimary": {
                        backgroundColor: "#91CB6C",
                      },
                      "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#EFF1F5",
                      },
                    }}
                  >
                    <LinearProgress style={{ borderRadius: 16 }} />
                  </Box>
                )}
                {!loading && (
                  <DropdownArrowGreen
                    style={
                      ferretAI
                      ? { transform: `rotate(180deg)` }
                      : {}
                    }
                    type={"more"}
                  />
                )}
              </div>

              {ferretAISummary && ferretAI && (
                <TypeWriter fullTxt={ferretAISummary} isSpeed={!PDFFerretAI}/>
              )}
            </div>
          )}
        {dataCat && dataCat.length > 0 && (
          <FilterByVerificationType
            verified={verified}
            setVerified={setVerified}
            partial={partial}
            setPartial={setPartial}
            needsReview={needsReview}
            setNeedsReview={setNeedsReview}
          />
        )}
        {dataCat && dataCat.length > 0 && (
          <ArticleCategoriesList
            data={dataCat}
            isClickable={true}
            onClick={onClick}
            isDataCategory={true}
            verified={verified}
            partial={partial}
            needsReview={needsReview}
          />
        )}
        {emptyDataCat && emptyDataCat.length > 0 && (
          <ArticleCategoriesList data={emptyDataCat} isClickable={false} />
        )}
      </div>
    </div>
  );
};
export default PersoneInfo;
