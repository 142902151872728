import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useSubscription } from "react-apollo";
import { UserContext } from "../../contexts/userContext";
import {
  CHECK_META_SEARCH_DATA,
  GET_CONFIGURATION,
  SAVE_ADHOC_SEARCH,
  USER_UPDATES,
} from "../../queries/queries";
import Variables from "../../utils/Variables";
import SaveSearchQuery from "../dashboard/saveSearchQuery";
import {
  AppBar,
  Backdrop,
  Box,
  ButtonBase,
  CircularProgress,
} from "@mui/material";
import { useLazyQuery } from "react-apollo";
import { useNavigate } from "react-router-dom";
import { BackWhiteIcon } from "../../images/svg/back_white";
import { MapWhiteIcon } from "../../images/svg/map_white";
import { searchResultStore } from "../../store/searchResultStore";
import {
  filterArticles,
  formatArticleAttributes,
  returnIntialTags,
  sortArticles,
} from "../../utils/filterUtils";
import savedSearchData from "../../utils/savedSearchData";
import {
  capitalizeCase,
  capitalizeSearchTermsTag,
  parseDate,
  removeDuplicateTags,
  titleCase,
} from "../../utils/utils";
import SectionListView from "../articleList/SectionListView";
import PersoneInfo from "../PersonInfo/personeInfo";
import PersoneInfoWeb from "../../web/components/PersonInfo/personeInfo";
import useIntialModalData from "../searchModalV2/useIntialModalData";
import useRefineSearch from "../searchModalV2/useRefineSearch";
import SaveSearchResultModal from "./saveSearchResultModal";
import "./style.css";
import ProcessingResultModal from "./processingResultModal";
import Styles from "./searchResults.module.css";
import SearchLimitExceedModal from "../searchLimitExceedModal";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import { ModelType } from "../searchLimitExceedModal/searchLimitExceedModal";
import html2pdf from "html2pdf.js";
import PDFDownload from "../../images/svg/pdf-download";
import Download from "../../images/svg/download";
import Article from "../../web/components/article/article";
import { generatePdf } from "../../utils/pdfGeneratorUtils";
import MapView from "../map/mapView";

var _ = require("lodash");

const defaultProfilePicture = require("../../images/default-avatar.png");
let originalArticles = [];
let theme;
let riskLevelsStatuses = {
  high: true,
  medium: true,
  low: true,
};
let temporaryStatusesOfFilters = null;
let hasData = false;
let showMetaSearchDialog = false;
let metaAPICount = 0;
let metaSearchArticleCount = 0;

const SearchResults = (props) => {
  const feed = props?.route?.params?.feed ?? props.feed;
  const {
    mPhoneNumberInput,
    mCountryInput,
    mCityInput,
    mStateInput,
    mAgeInput,
    queryData,
    savedTracerQueryId,
    savedTahoeId,
    mOldMetaData,
    navigatedFrom,
    clickedPotential,
    lastQuery,
    scrollPosition = 0,
    articleSource = "SEARCH",
    searchIds,
    linkedInData,
    feedbackOptions,
    profilePic,
    searchList,
    articleDetail,
  } = props?.route?.params?.feed ?? props.feed;
  const updatedOldData = {
    ...mOldMetaData,
    Person: mOldMetaData?.Person?.trim(),
  };

  const [ferretAI, setFerretAI] = useState(false);
  const [ferretAISummary, setFerretAISummary] = useState();
  const [educationMore, setEducationMore] = useState(false);
  const [experienceMore, setExperienceMore] = useState(false);
  const [showOthers, setShowOthers] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [articleSummary, setArticleSummary] = useState("");
  const [showArticleSummary, setShowArticleSummary] = useState(false);
  const [mapFilteredArticles, setMapFilteredArticles] = useState([]);

  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(0);

  const fullName = props?.route?.params?.feed?.fullName;
  const searchTerm = feed?.searchTerm;

  const navigate = useNavigate();
  let initialSearchSettings = {};

  const [openMapView, setOpenMapView] = useState();

  const openMapDashboard = searchResultStore((state) => state.openMapDashboard);
  const setOpenMapDashboard = searchResultStore(
    (state) => state.setOpenMapDashboard
  );

  if (!!feed && feed.hasOwnProperty("searchSettings"))
    initialSearchSettings = feed.searchSettings;

  const categoryFilterData = props?.feed?.categoryFilterData;

  const profilePic1 = searchResultStore((state) => state.profilePic1);
  const setProfilePic1 = searchResultStore((state) => state.setProfilePic1);

  const feedbackOptions1 = searchResultStore((state) => state.feedbackOptions1);
  const setFeedbackOptions1 = searchResultStore(
    (state) => state.setFeedbackOptions1
  );

  const processArticleDone = searchResultStore(
    (state) => state.processArticleDone
  );
  const setProcessArticleDone = searchResultStore(
    (state) => state.setProcessArticleDone
  );

  const processArticleInProgress = searchResultStore(
    (state) => state.processArticleInProgress
  );
  const setProcessArticleInProgress = searchResultStore(
    (state) => state.setProcessArticleInProgress
  );

  const openArticleDetail = searchResultStore(
    (state) => state.openArticleDetail
  );
  const setOpenArticleDetail = searchResultStore(
    (state) => state.setOpenArticleDetail
  );

  const showProcessArticlesModal = searchResultStore(
    (state) => state.showProcessArticlesModal
  );
  const setShowProcessArticlesModal = searchResultStore(
    (state) => state.setShowProcessArticlesModal
  );

  const monitorSearch = searchResultStore((state) => state.monitorSearch);
  const setMonitorSearch = searchResultStore((state) => state.setMonitorSearch);

  const searchSettingsRelevancyStore = searchResultStore(
    (state) => state.searchSettingsRelevancy
  );
  const setSearchSettingsRelevancy = searchResultStore(
    (state) => state.setSearchSettingsRelevancy
  );

  const saveSearch = searchResultStore((state) => state.saveSearch);
  const setSaveSearch = searchResultStore((state) => state.setSaveSearch);

  const linkedInUserData = searchResultStore((state) => state.linkedInUserData);
  const setLinkedInUserData = searchResultStore(
    (state) => state.setLinkedInUserData
  );

  const showArticleDetails = searchResultStore(
    (state) => state.showArticleDetails
  );
  const setArticleDetails = searchResultStore(
    (state) => state.setArticleDetails
  );

  const chkBxNotifyUser = searchResultStore((state) => state.chkBxNotifyUser);
  const setChkBxNotifyUser = searchResultStore(
    (state) => state.setChkBxNotifyUser
  );

  const configurationData = searchResultStore(
    (state) => state.configurationData
  );
  const setConfigurationData = searchResultStore(
    (state) => state.setConfigurationData
  );

  const ConfigurationLoading = searchResultStore(
    (state) => state.ConfigurationLoading
  );
  const setConfigurationLoading = searchResultStore(
    (state) => state.setConfigurationLoading
  );

  const searchSettings = searchResultStore((state) => state.searchSettings);
  const setSearchSettings = searchResultStore(
    (state) => state.setSearchSettings
  );

  const filteredArticles = searchResultStore((state) => state.filteredArticles);
  const setFilteredArticles = searchResultStore(
    (state) => state.setFilteredArticles
  );

  const filteredArticles2 = searchResultStore(
    (state) => state.filteredArticles2
  );
  const setFilteredArticles2 = searchResultStore(
    (state) => state.setFilteredArticles2
  );

  const originalArticles2 = searchResultStore(
    (state) => state.originalfilteredArticles
  );
  const setOriginalArticles2 = searchResultStore(
    (state) => state.setOriginalFilteredArticles
  );

  const originalfilteredArticles = searchResultStore(
    (state) => state.originalfilteredArticles
  );
  const setOriginalFilteredArticles = searchResultStore(
    (state) => state.setOriginalFilteredArticles
  );

  const isEnableFilter = searchResultStore((state) => state.isEnableFilter);
  const setEnableFilter = searchResultStore((state) => state.setEnableFilter);

  const isFromPopup = searchResultStore((state) => state.isFromPopup);
  const setFromPopup = searchResultStore((state) => state.setFromPopup);

  const articleFilterData = searchResultStore(
    (state) => state.articleFilterData
  );
  const setArticleFilterData = searchResultStore(
    (state) => state.setArticleFilterData
  );

  const personalInfo = searchResultStore((state) => state.personalInfo);
  const setPersonalInfo = searchResultStore((state) => state.setPersonalInfo);

  const aKAList = searchResultStore((state) => state.aKAList);
  const setAKAList = searchResultStore((state) => state.setAKAList);

  const articleTypeCount = searchResultStore((state) => state.articleTypeCount);
  const setArticleTypeCount = searchResultStore(
    (state) => state.setArticleTypeCount
  );

  const isFilterApplied = searchResultStore((state) => state.isFilterApplied);
  const setIsFilterApplied = searchResultStore(
    (state) => state.setIsFilterApplied
  );

  const recentSearchId = searchResultStore((state) => state.recentSearchId);
  const setRecentSearchId = searchResultStore(
    (state) => state.setRecentSearchId
  );

  const totalArticle = searchResultStore((state) => state.totalArticle);
  const setTotalArticle = searchResultStore((state) => state.setTotalArticle);

  const saveSearchVariables = searchResultStore(
    (state) => state.saveSearchVariables
  );
  const setSaveSearchVariables = searchResultStore(
    (state) => state.setSaveSearchVariables
  );

  const savedContactID = searchResultStore((state) => state.savedContactID);
  const setSavedContactID = searchResultStore(
    (state) => state.setSavedContactID
  );

  const isShowPersonalInfo = searchResultStore(
    (state) => state.isShowPersonalInfo
  );
  const setShowPersonalInfo = searchResultStore(
    (state) => state.setShowPersonalInfo
  );

  const selectedFilter = searchResultStore((state) => state.selectedFilter);
  const setSelectedFilter = searchResultStore(
    (state) => state.setSelectedFilter
  );

  const sortState = searchResultStore((state) => state.sortState);
  const setSortState = searchResultStore((state) => state.setSortState);

  const showStrongMatchArticles = searchResultStore(
    (state) => state.showStrongMatchArticles
  );
  const setShowStrongMatchArticles = searchResultStore(
    (state) => state.setShowStrongMatchArticles
  );

  const showWeakMatchArticles = searchResultStore(
    (state) => state.showWeakMatchArticles
  );
  const setShowWeakMatchArticles = searchResultStore(
    (state) => state.setShowWeakMatchArticles
  );

  const filterAttributes = searchResultStore((state) => state.filterAttributes);
  const setFilterAttributes = searchResultStore(
    (state) => state.setFilterAttributes
  );

  const readFilter = searchResultStore((state) => state.readFilter);
  const setReadFilter = searchResultStore((state) => state.setReadFilter);

  const unreadFilter = searchResultStore((state) => state.unreadFilter);
  const setUnreadFilter = searchResultStore((state) => state.setUnreadFilter);

  const attributes = searchResultStore((state) => state.attributes);
  const setAttributes = searchResultStore((state) => state.setAttributes);

  const headerTags = searchResultStore((state) => state.headerTags);
  const setHeaderTags = searchResultStore((state) => state.setHeaderTags);

  const saveRelevancyData = searchResultStore(
    (state) => state.saveRelevancyData
  );
  const setSaveRelevancyData = searchResultStore(
    (state) => state.setSaveRelevancyData
  );

  const deleteRelevancyData = searchResultStore(
    (state) => state.deleteRelevancyData
  );
  const setDeleteRelevancyData = searchResultStore(
    (state) => state.setDeleteRelevancyData
  );

  const verified = searchResultStore((state) => state.verified);
  const setVerified = searchResultStore((state) => state.setVerified);

  const partial = searchResultStore((state) => state.partial);
  const setPartial = searchResultStore((state) => state.setPartial);

  const needsReview = searchResultStore((state) => state.needsReview);
  const setNeedsReview = searchResultStore((state) => state.setNeedsReview);

  const refreshList = searchResultStore((state) => state.refreshList);
  const setRefreshList = searchResultStore((state) => state.setRefreshList);

  const showHeader = searchResultStore((state) => state.showHeader);
  const setShowHeader = searchResultStore((state) => state.setShowHeader);

  const showSaveConfirmationAlert = searchResultStore(
    (state) => state.showSaveConfirmationAlert
  );
  const setShowSaveConfirmationAlert = searchResultStore(
    (state) => state.setShowSaveConfirmationAlert
  );

  const modalFilterVisible = searchResultStore(
    (state) => state.modalFilterVisible
  );
  const setModalFilterVisible = searchResultStore(
    (state) => state.setModalFilterVisible
  );

  const summaryPosition = searchResultStore((state) => state.summaryPosition);
  const setSummaryPosition = searchResultStore(
    (state) => state.setSummaryPosition
  );

  const articlePosition = searchResultStore((state) => state.articlePosition);
  const setArticlePosition = searchResultStore(
    (state) => state.setArticlePosition
  );

  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );

  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [isPDFGenerate, setPDFGenerate] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(
    false
  );

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };
  const cancelPopup = () => {
    setSubscriptionModalVisible(false);
    setSearchLimitExceed(false);
    backToSearchComponent();
  };

  useEffect(() => {
    setShowPersonalInfo(!searchList);
    if (!searchList || !articleDetail) {
      setShowHeader(true);
    } else if (searchList || articleDetail) {
      setShowHeader(false);
    }
  }, [searchList, articleDetail]);

  useEffect(() => {
    if (isShowPersonalInfo) {
      setShowHeader(true);
    }
  }, [isShowPersonalInfo]);

  useEffect(() => {
    if (
      linkedInData &&
      Object.getOwnPropertyNames(linkedInUserData).length == 0
    )
      setLinkedInUserData(linkedInData);

    if (feedbackOptions && !feedbackOptions1)
      setFeedbackOptions1(feedbackOptions);

    if (profilePic && !profilePic1) setProfilePic1(profilePic);
  }, []);

  const broadcast = new BroadcastChannel("background-linkedin-message");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        updateLinkedInView(res.data.data);
      };
    }
  }, []);

  const {
    data: apolloSubscriptionData,
    error: apolloSubscriptionError,
  } = useSubscription(USER_UPDATES);

  useEffect(() => {
    if (apolloSubscriptionData) {
      updateLinkedInView(apolloSubscriptionData.userUpdates);
    } else if (apolloSubscriptionError) {
      console.error("apolloSubscriptionError", apolloSubscriptionError);
      // First Retry immedietly
      // After 5 seconds -> Call again the request and listening the response
      // 30 seconds -> After receiving the failure, couldn't successful this one, then email it to Gavin and Jay by calling util funtion.
    }
  }, [apolloSubscriptionData, apolloSubscriptionError]);

  useEffect(() => {
    if (openMapDashboard && isShowPersonalInfo) {
      setOpenMapView(openMapDashboard);
    }
  }, [isShowPersonalInfo]);

  const updateLinkedInView = (payload) => {
    if (
      payload?.type === "linkedin-profile-match" &&
      payload?.potentialMatchId === Variables.potentialMatchId
    ) {
      if (payload?.linkedinProfile) {
        let data = JSON.parse(payload?.linkedinProfile);
        let jsonData = data && data.length > 0 && JSON.parse(data);
        setProfilePic1(jsonData?.profile_picture_src);
        if (jsonData) {
          jsonData.education = jsonData.education?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
          jsonData.experience = jsonData.experience?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
        }
        setLinkedInUserData(jsonData);
      }
    }
  };

  useEffect(() => {
    if (
      originalfilteredArticles == null ||
      originalfilteredArticles.length == 0
    ) {
      setOriginalFilteredArticles(originalArticles);
      setFilteredArticles2(filteredArticles);
      setArticleFilterData(
        props?.feed?.articleFilterData.filter((item) => item.count)
      );
      setPersonalInfo(props?.feed?.personalInfo);
      setFeedbackOptions1(props?.feed?.feedbackOptions);
      setProfilePic1(props?.feed?.profilePic);
      setSearchSettings(initialSearchSettings);
      setSavedContactID(feed?.savedContactID);
      setRecentSearchId(feed?.irrelevantArticleId);
      setArticleTypeCount(props?.feed?.articleTypeCount);
      setAKAList(
        props?.feed?.aKAList?.map((item) => {
          return { param: item, aka: true };
        })
      );
      setModalFilterVisible(Variables.modalFilterVisible);
      filterArticleTypeCount();
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!showArticleDetails) {
      if (isShowPersonalInfo) {
        window.scrollTo(0, summaryPosition);
        setArticlePosition(0);
      } else {
        setTimeout(() => {
          window.scrollTo(0, articlePosition);
        }, 200);
      }
    }
  }, [showArticleDetails, isShowPersonalInfo]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isShowPersonalInfo]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0 && !showArticleDetails) {
      if (isShowPersonalInfo) {
        setSummaryPosition(position);
      } else if (!articleDetail) {
        setArticlePosition(position);
      }
    }
  };

  useEffect(() => {
    return () => {
      originalArticles = [];
      temporaryStatusesOfFilters = null;
      hasData = false;
      showMetaSearchDialog = false;
      metaAPICount = 0;
      localStorage.setItem("reload", false);
    };
  }, []);

  const timezone = moment.tz.guess();

  const {
    refineSearch,
    data,
    loading,
    getArticleDetailsFromRefineSearch,
    mapSingleArticle,
    getReadArticlesIds,
    getPersonalInfo,
    getArticleFilterData,
    getPotentialMatchId,
    getContactId,
    getFeedbackOptions,
    getProfilePic,
  } = useRefineSearch();

  let isFirstOpen = false;

  // useEffect(() => {
  //   if (isFocused) {
  //     const backAction = () => {
  //       getSaveSearchData()
  //       return true
  //     }
  //     BackHandler.addEventListener("hardwareBackPress", backAction);

  //     return () => BackHandler.removeEventListener("hardwareBackPress", backAction)
  //   }
  // }, [isShowPersonalInfo, saveSearchVariables])

  const {
    loadingData,
    searchSettings: searchSettingsRelevancy,
    getModalDataForMetaTag,
  } = useIntialModalData({}, "", "");

  useEffect(() => {
    if (refreshList == true && lastQuery) {
      setRefreshList(false);

      let query = JSON.parse(JSON.stringify(lastQuery));
      let meta_data = JSON.parse(query.variables.fullname.meta_data);

      if (
        saveRelevancyData?.Organizations &&
        saveRelevancyData?.Organizations?.length
      ) {
        meta_data.Company =
          meta_data.Company && meta_data.Company != ""
            ? meta_data.Company +
              ";" +
              saveRelevancyData?.Organizations?.join(";")?.toString()
            : saveRelevancyData?.Organizations?.join(";")?.toString();
      }
      if (saveRelevancyData?.Places && saveRelevancyData?.Places?.length) {
        meta_data.Place =
          meta_data.Place && meta_data.Place != ""
            ? meta_data.Place +
              ";" +
              saveRelevancyData?.Places?.join(";")?.toString()
            : saveRelevancyData?.Places?.join(";")?.toString();
      }

      if (
        deleteRelevancyData?.Organizations &&
        deleteRelevancyData?.Organizations?.length
      ) {
        meta_data.Company =
          meta_data.Company &&
          meta_data.Company != "" &&
          meta_data.Company.split(";");

        meta_data.Company = meta_data?.Company?.filter((item) => {
          return !deleteRelevancyData?.Organizations?.includes(item);
        })
          .join(";")
          ?.toString();

        if (
          saveRelevancyData?.Organizations &&
          saveRelevancyData?.Organizations?.length
        ) {
          saveRelevancyData.Organizations = saveRelevancyData?.Organizations?.filter(
            (item) => {
              return !deleteRelevancyData?.Organizations?.includes(item);
            }
          );
        }
      }
      if (deleteRelevancyData?.Places && deleteRelevancyData?.Places?.length) {
        meta_data.Place =
          meta_data.Place &&
          meta_data.Place != "" &&
          meta_data.Place.split(";");

        meta_data.Place = meta_data?.Place?.filter((item) => {
          return !deleteRelevancyData?.Places?.includes(item);
        })
          .join(";")
          ?.toString();

        if (saveRelevancyData?.Places && saveRelevancyData?.Places?.length) {
          saveRelevancyData.Places = saveRelevancyData?.Places?.filter(
            (item) => {
              return !deleteRelevancyData?.Places?.includes(item);
            }
          );
        }
      }

      query.variables.fullname.meta_data = JSON.stringify(meta_data);

      if (
        query.variables.fullname?.contactId == undefined &&
        Variables.savedRelevancyContactId?.length > 0
      )
        query.variables.fullname.contactId = Variables.savedRelevancyContactId;

      query.variables.fullname.refresh = true;

      const name =
        searchSettings?.initialSearchString ??
        feed.searchSettings?.initialSearchString ??
        fullName ??
        "";
      const searchType =
        meta_data?.Person && meta_data?.Person != ""
          ? "PERSON"
          : meta_data?.Company?.toLowerCase() == name?.toLowerCase()
          ? "COMPANY"
          : meta_data?.Location?.toLowerCase() == name?.toLowerCase()
          ? "LOCATION"
          : meta_data?.Place?.toLowerCase() == name?.toLowerCase()
          ? "PLACE"
          : meta_data?.Nationality?.toLowerCase() == name?.toLowerCase()
          ? "PLACE"
          : "PERSON";
      getModalDataForMetaTag(
        searchType,
        meta_data,
        name,
        mPhoneNumberInput,
        mCountryInput,
        mCityInput,
        mStateInput,
        mAgeInput
      );
      refineSearch(query);
      setDeleteRelevancyData("");
      if (Variables.isAddSearches && Variables.savedSearchId) {
        setSavedContactID(Variables.savedSearchId);
        Variables.savedSearchId = undefined;
      }
    }
  }, [refreshList]);

  useEffect(() => {
    if (!!data && !loading && data && data.hasOwnProperty("refine_search")) {
      if (
        data?.refine_search?.result_type?.toUpperCase() ===
        "SUBSCRIPTION LIMIT REACHED"
      ) {
        // Alert.alert(
        //   "Error",
        //   data?.refine_search?.result_type?.toUpperCase(),
        //   [
        //     {
        //       text: "OK",
        //       style: "default",
        //     },
        //   ],
        //   { cancelable: true }
        // );
        return;
      }
      Variables.potentialMatchId = getPotentialMatchId();
      Variables.contactId = getContactId();
      const articleFilterData = getArticleFilterData();
      setArticleFilterData(articleFilterData.filter((item) => item.count));
      const parsedArticles = getArticleDetailsFromRefineSearch()
        ?.filter((item) => {
          return item;
        })
        .map((item) =>
          mapSingleArticle(
            item,
            searchSettings?.initialSearchString ??
              feed.searchSettings?.initialSearchString ??
              fullName ??
              "",
            getReadArticlesIds()
          )
        );
      originalArticles = getFormatedArticles(parsedArticles);
      setOriginalArticles2(originalArticles);
      setOriginalFilteredArticles(originalArticles);
      filterAction();
      setPersonalInfo(getPersonalInfo());
      setFeedbackOptions1(getFeedbackOptions());
      setProfilePic1(getProfilePic());

      const metaArticleCount = articleFilterData
        ?.filter((item) => item.category == "MetaSearch")
        .map((item) => item.count)
        ?.toString();

      if (showMetaSearchDialog && processArticleDone.length > 0) {
        setShowSaveConfirmationAlert({ status: false, msg: "" });
        setShowProcessArticlesModal({
          status: true,
          count: metaArticleCount ?? 0,
          isSameCount: metaSearchArticleCount == metaArticleCount,
        });
        showMetaSearchDialog = false;
      }

      metaSearchArticleCount = metaArticleCount;
    }
  }, [data, loading]);

  useEffect(() => {
    if (searchSettings)
      setSaveSearchVariables(
        savedSearchData(
          searchSettings?.intitialMetadata,
          searchSettings?.initialSearchString,
          feed?.irrelevantArticleId,
          props?.feed?.articleTypeCount,
          savedContactID,
          savedTracerQueryId,
          savedTahoeId,
          queryData,
          aKAList
        )
      );
  }, [searchSettings, savedContactID, aKAList]);

  const { user, setUser } = useContext(UserContext);
  const { saveSearchAndParse } = SaveSearchQuery(setUser);

  const [
    getConfigData,
    { loading: configLoading, data: configData },
  ] = useLazyQuery(GET_CONFIGURATION);

  useEffect(() => {
    if (configData && !configLoading) {
      setConfigurationData(configData);
      setConfigurationLoading(configLoading);
    }
  }, [configData, configLoading]);

  const [
    checkMetaSearch,
    {
      loading: checkMetaSearchLoading,
      data: checkMetaSearchData,
      error: checkMetaSearchError,
    },
  ] = useLazyQuery(CHECK_META_SEARCH_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!checkMetaSearchLoading && checkMetaSearchData) {
      let data = checkMetaSearchData.checkMetaSearchData;
      switch (true) {
        case data.hasData == true && data.refresh == false:
          callGetMetaSearchAfter10sec(data);
          break;
        case data.hasData == true && data.refresh == true:
          callGetMetaSearchAfter10sec(data);
          onGetMetaSearchResponse(false);
          break;
        case data.hasData == false && data.refresh == false:
          onGetMetaSearchResponse(true);
          break;
      }
      metaAPICount++;
    }
  }, [checkMetaSearchLoading, checkMetaSearchData]);

  const onGetMetaSearchResponse = (finalOutputReceive) => {
    if (finalOutputReceive) {
      hasData = false;
      setProcessArticleDone(processArticleInProgress);
      setProcessArticleInProgress([]);
    }
    if (metaAPICount > 0) {
      showMetaSearchDialog = true;
      var query = JSON.parse(JSON.stringify(lastQuery));
      query.variables.fullname.refresh = true;
      refineSearch(query);
    }
  };

  const callGetMetaSearchAfter10sec = (data) => {
    if (!hasData) hasData = data.hasData;

    setProcessArticleInProgress(data.source);
    setProcessArticleDone(data.source);
    setTimeout(() => {
      callGetMetaSearchData();
    }, 10000);
  };

  const callGetMetaSearchData = () => {
    checkMetaSearch({
      variables: {
        searchIds: searchIds,
      },
    });
  };

  useEffect(() => {
    callGetMetaSearchData();
  }, []);

  const [
    saveAdhocSearch,
    { data: saveData, loading: saveLoading, error: saveError },
  ] = useMutation(SAVE_ADHOC_SEARCH);

  useEffect(() => {
    if (!saveLoading && (saveData || saveError)) {
      if (saveData?.saveAdhocSearch?.data?.toLowerCase() === "limit reached") {
        const updateUserInfo = { ...user, watchedLimitExceed: true };
        setUser(updateUserInfo);
        setSearchLimitExceed(true);
      } else {
        backToSearchComponent();
      }
    }
  }, [saveData, saveLoading, saveError]);

  useEffect(() => {
    getConfigData();
    Variables.savedRelevancyContactId = "";
  }, []);

  const backToSearchComponent = () => {
    if (
      clickedPotential &&
      navigatedFrom != "SavedSearchesPreview" &&
      navigatedFrom != "SavedContactsArticles" &&
      searchSettings?.initialPotentialMatch?.length
    ) {
      // let mSearchSettings = [...searchSettings]
      // mSearchSettings.initialQuestions = []

      let mSearchSettings = {
        ...searchSettings,
        initialSearchString: queryData?.title
          ? queryData?.title
          : searchSettings?.initialSearchString,
      };
      let data = {
        inNewSearch: true,
        isRecentSearch: false,
        fromBackSearch: true,
        isSearchMe: isFromPopup ? false : feed?.searchMe,
        searchSettings: mSearchSettings,
        cleanStateOnBlur: false,
        isAutoFocus: false,
        isEdit: false,
        mPhoneNumberInput: mPhoneNumberInput,
        mCountryInput: mCountryInput,
        mCityInput: mCityInput,
        mStateInput: mStateInput,
        mAgeInput: mAgeInput,
        mQueryData: queryData,
        mSavedTracerQueryId: savedTracerQueryId,
        mSavedTahoeId: savedTahoeId,
        recentSearchId: recentSearchId,
        scrollPosition: scrollPosition,
      };
      setTimeout(() => {
        navigate("/searchComponent", {
          state: data,
        });
      }, 10);
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (
      feed?.searchMe &&
      searchSettings &&
      searchSettings?.intitialMetadata &&
      searchSettings?.initialSearchString
    ) {
      saveSearchAndParse(
        searchSettings?.intitialMetadata,
        searchSettings?.initialSearchString
      );
    }
  }, [searchSettings]);

  useEffect(() => {
    async function fetchData() {
      reloadScreenData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (categoryFilterData) {
      setAttributes(
        returnIntialTags(
          categoryFilterData?.Places,
          categoryFilterData?.Organizations,
          searchSettings,
          aKAList,
          categoryFilterData?.People,
          categoryFilterData?.Parties,
          categoryFilterData?.Attorneys
        )
      );
    }
  }, [categoryFilterData]);

  const reloadScreenData = () => {
    if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
      setTimeout(() => {
        let riskCategory = "";
        const mmArticle = filteredArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        originalArticles = originalArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });

        setOriginalArticles2(originalArticles);

        setFilteredArticles(formatArticleAttributes(mmArticle));
        const countArticle = {
          highRisk:
            articleTypeCount?.highRisk - (riskCategory == "high" ? 1 : 0),
          news: articleTypeCount?.news - (riskCategory == "low" ? 1 : 0),
          alerts: articleTypeCount?.alerts - (riskCategory == "medium" ? 1 : 0),
        };
        setArticleTypeCount(countArticle);
        Variables.articleIdMarkNo = false;
      }, 200);
    }

    if (Variables.isAddSearches && Variables.savedSearchId) {
      setSavedContactID(Variables.savedSearchId);
      Variables.savedSearchId = undefined;
    }
  };

  function getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (_.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  }

  const onArticleTypeClick = (data) => {
    setSelectedFilter(data.category);
    if (isShowPersonalInfo) {
      // If you are on personal info screen
      // setShowPersonalInfo(false);
      navigate("/SearchResultsDetails", {
        state: { ...props.feed, searchList: true },
      });
    } else {
      // Other cases, Article detail screen
      setShowHeader(true);
      setOpenArticleDetail({ status: false, data: {} });
      let mArticleTypeCount = [...articleFilterData];
      setArticleFilterData([]);
      setTimeout(() => {
        setArticleFilterData(mArticleTypeCount);
      }, 1);
    }
  };

  useEffect(() => {
    if (isShowPersonalInfo) {
      setSortState(2);
      setReadFilter(false);
      setUnreadFilter(false);
      setShowWeakMatchArticles(false);
      setShowStrongMatchArticles(false);
      setFilterAttributes({
        People: [],
        Organizations: [],
        Places: [],
        Parties: [],
        Attorneys: [],
      });
    }
  }, [isShowPersonalInfo]);

  const getSaveSearchData = () => {
    if (searchList) {
      navigate(-1, { replace: true });
      return;
    } else if (!isShowPersonalInfo) {
      setShowPersonalInfo(true);
      return;
    }
    if (!feed?.searchMe) {
      if (navigatedFrom == "SavedSearchesPreview") {
        navigate(-1, { replace: true });
      } else if (updatedOldData && navigatedFrom === "SavedSearches") {
        const isMetaDataSimilar = getObjectDiff(
          updatedOldData,
          initialSearchSettings?.intitialMetadata
        );
        if (isMetaDataSimilar.length) {
          saveMySearchAndShowConfirmation(searchSettings?.initialSearchString);
        } else {
          navigate(-1, { replace: true });
        }
      } else {
        saveMySearchAndShowConfirmation(searchSettings?.initialSearchString);
      }
    } else {
      navigate(-1, { replace: true });
    }
  };

  const saveMySearchAndShowConfirmation = (initialSearchString) => {
    setShowSaveConfirmationAlert({ status: true, msg: initialSearchString });
  };

  const saveMySearches = () => {
    if (saveSearchVariables == undefined) return;
    saveSearchVariables.data.searchIds = searchIds;
    saveSearchVariables.data.notifyUser = chkBxNotifyUser;
    saveSearchVariables.data.monitorSearch = monitorSearch;
    saveSearchVariables.data.saveSearch = monitorSearch ? true : saveSearch;
    if (personalInfo?.personalSummary?.estimatedAge) {
      saveSearchVariables.data.age =
        personalInfo?.personalSummary?.estimatedAge;
    }
    setShowSaveConfirmationAlert({ status: false, msg: "" });
    saveAdhocSearch({
      variables: saveSearchVariables,
    });
    Variables.isAddSearches = true;
  };

  const filterAction = (isRiskRankingApplied, fromMap) => {
    if (!originalArticles || originalArticles.length === 0) {
      setFilteredArticles([]);
      return;
    }
    const selectedParentCategory = getSelectedParentCategory();

    let filterByArticleType = [...originalArticles];

    filterByArticleType = filterByArticleType.filter(
      (item) => riskLevelsStatuses[item.riskCategory]
    );

    setTotalArticle(filterByArticleType.length);

    if (
      selectedParentCategory &&
      (selectedParentCategory.length || isFirstOpen)
    ) {
      filterByArticleType = filterByArticleType.filter(
        (item) =>
          selectedParentCategory.includes(item.parentCategory) ||
          selectedParentCategory.includes("All")
      );
    }

    if (!isRiskRankingApplied) {
      let data = formatArticleAttributes(filterByArticleType);
      setFilteredArticles(data);
      if (fromMap) {
        setMapFilteredArticles(formatArticleAttributes(filterByArticleType));
      }
    } else {
      filterArticleTypeCount();
    }

    setEnableFilter(true);
  };

  const getSelectedParentCategory = () => {
    let categoryType = [];
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.status) {
          categoryType = [...categoryType, type?.label];
        } else if (type.count && type.count > 0) isFirstOpen = true;
      });
    }
    return categoryType;
  };

  const getRandomDate = () => {
    const start = new Date(2012, 0, 1);
    const end = new Date();
    const randomDate = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );

    return moment.tz(randomDate, timezone);
  };

  const getFormatedArticles = (articles) => {
    const now = moment();
    const riskCategories = {
      HIGH: "high",
      MEDIUM: "medium",
      LOW: "low",
    };

    return articles.map((article) => {
      if (article.createdAt) {
        article.date = moment(parseInt(article.createdAt)).fromNow();
        article.originalDate = moment(parseInt(article.createdAt)).tz(timezone);
      } else {
        if (!article.date) {
          article.date = getRandomDate().format();
        }
        let mDate = moment.parseZone(article.date).tz(timezone);
        article.originalDate = mDate;
        if (mDate.isValid()) {
          if (now.diff(mDate, "years") > 0) {
            article.date = mDate.format("L - LT");
          } else {
            article.date = mDate.fromNow();
          }
        } else {
          article.date = null;
        }
      }

      if (!article.profilePicture) {
        article.profilePicture = defaultProfilePicture;
      }
      article.riskCategory = riskCategories[article.riskCategory] ?? "low";
      return article;
    });
  };

  useEffect(() => {
    if (!feed) return;
    let testArticles =
      feed?.articles?.map((item) => {
        return { ...item };
      }) ?? [];

    if (originalArticles?.length == 0) {
      if (originalArticles2?.length == 0) {
        originalArticles = getFormatedArticles(testArticles);
      } else {
        originalArticles = originalArticles2;
      }

      // This will call in case of browser reload
      let reload = localStorage.getItem("reload");
      if (reload == "true") {
        localStorage.setItem("reload", false);
        setRefreshList(true);
      }
    }
    filterAction();
  }, [feed]);

  useEffect(() => {
    filterAction();
  }, [riskLevelsStatuses]);

  const handlePressEditSearch = () => {
    if (loading || loadingData) return;

    let searchData = { ...searchSettings };
    searchData.initialPotentialMatch = [];
    if (searchSettingsRelevancy)
      setSearchSettingsRelevancy(searchSettingsRelevancy);
    let data = {
      inNewSearch: true,
      fromEditSearch: true,
      isRecentSearch: false,
      fromBackSearch: false,
      isSearchMe: isFromPopup ? false : feed?.searchMe,
      searchSettings: searchSettingsRelevancy
        ? searchSettingsRelevancy
        : searchSettingsRelevancyStore
        ? searchSettingsRelevancyStore
        : searchData,
      cleanStateOnBlur: false,
      isAutoFocus: false,
      isEdit: true,
      mPhoneNumberInput: mPhoneNumberInput,
      mCountryInput: mCountryInput,
      mCityInput: mCityInput,
      mStateInput: mStateInput,
      mAgeInput: mAgeInput,
      mQueryData: queryData,
      mSavedTracerQueryId: savedTracerQueryId,
      mSavedTahoeId: savedTahoeId,
      recentSearchId: recentSearchId,
      searchList: searchList,
    };
    navigate("/searchComponent", {
      state: data,
    });
  };
  const handlePressFilter = () => setModalFilterVisible(!modalFilterVisible);

  const showProfilePicture = feed.searchMe && user.profilePhoto;

  useEffect(() => {
    if (headerTags?.length == 0) {
      let tags = [
        ...(searchSettings?.initialSearchParams ??
          feed?.searchSettings?.initialSearchParams ??
          []),
      ];
      if (aKAList) tags?.splice(1, 0, ...aKAList);
      if (personalInfo?.personalSummary?.estimatedAge)
        tags?.splice(
          1,
          0,
          ...[
            {
              type: "age",
              param: `Est Age ${personalInfo?.personalSummary?.estimatedAge}`,
            },
          ]
        );
      setHeaderTags(removeDuplicateTags(tags));
    }
  }, [aKAList]);

  const header = () => {
    return (
      <AppBar component="nav" position="fixed">
        {
          <div className={Styles.headerLeftContainer}>
            <div className={Styles.headerContainer}>
              <div className={Styles.leftHeaderContainer}>
                <button
                  className={Styles.backStyle}
                  onClick={() => getSaveSearchData()}
                >
                  <BackWhiteIcon />
                </button>
                {/* <div>
                  {feed?.profilePictureUri || showProfilePicture ? (
                    <img
                      source={{
                        uri: showProfilePicture
                          ? user?.profilePhoto
                          : user?.profilePictureUri,
                      }}
                      style={profilePictureStyle}
                    />
                  ) : (
                    <DefaultAvatar style={profilePictureStyle} />
                  )}
                </div> */}
                <span className={Styles.headerLeftFullName}>
                  {capitalizeCase(
                    searchSettings?.initialSearchString ??
                      feed.searchSettings?.initialSearchString ??
                      fullName ??
                      "",
                    true
                  )}
                </span>
              </div>
              <div className={Styles.headerRightContainer}>
                <button
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    setOpenMapView(true);
                    setOpenMapDashboard(true);
                  }}
                >
                  <MapWhiteIcon />
                </button>
                <ButtonBase
                  onClick={handlePressEditSearch}
                  disabled={navigatedFrom == "SavedSearchesPreview"}
                >
                  <div
                    className={
                      navigatedFrom == "SavedSearchesPreview"
                        ? Styles.headerRightEditSearchDisabedButton
                        : Styles.headerRightEditSearchButton
                    }
                  >
                    <span
                      className={
                        navigatedFrom == "SavedSearchesPreview"
                          ? Styles.headerRightEditSearchDisabledText
                          : Styles.headerRightEditSearchText
                      }
                    >
                      Edit Search
                    </span>
                  </div>
                </ButtonBase>
                {/* <Settings
                fillColor={headerRightStyle.settings.fillColor}
                style={headerRightStyle.settings}
              /> */}
              </div>
            </div>
            <div
              id="searchResultHeaderTags"
              className={Styles.flatlistContainer}
            >
              {headerTags.map((item, index) =>
                item?.aka ? (
                  <div className={Styles.tagListContainer}>
                    <span
                      className={Styles.tagListTitle}
                      style={{
                        fontWeight: "600",
                        color: "#949494",
                      }}
                    >
                      {"AKA "}
                      <span className={Styles.tagListTitle}>{item.param}</span>
                    </span>
                  </div>
                ) : item?.type == "age" ? (
                  <div className={Styles.tagListContainer}>
                    <span
                      className={Styles.tagListTitle}
                      style={{
                        fontWeight: "600",
                        color: "#949494",
                      }}
                    >
                      <span className={Styles.tagListTitle}>{item.param}</span>
                    </span>
                  </div>
                ) : (
                  item.param.split(";")?.map((itemTag) => {
                    if (
                      item?.updated == 5 &&
                      !item?.hasOwnProperty("indexOfQuestion") &&
                      !item?.hasOwnProperty("indexOfAnswer")
                    ) {
                      itemTag = `App. ${itemTag} y/o`;
                    }
                    return (
                      <div className={Styles.tagListContainer}>
                        <span className={Styles.tagListTitle}>
                          {capitalizeSearchTermsTag(itemTag, index, true)}
                        </span>
                      </div>
                    );
                  })
                )
              )}
            </div>
          </div>
        }
      </AppBar>
    );
  };

  const filterArticleTypeCount = () => {
    if (articleFilterData) {
      let mArticleFilter = [...articleFilterData];
      const updatedArray = mArticleFilter.map((itemType) => {
        return {
          ...itemType,
          count: originalfilteredArticles.filter(
            (itemArticle) =>
              itemType?.category === (itemArticle.parentCategory ?? null)
          ).length,
        };
      });
      setArticleFilterData(updatedArray);
    }
  };

  const onFilterCompleted = (
    riskLevel,
    filterData,
    isRiskRankingApplied = false,
    fromMap
  ) => {
    riskLevelsStatuses = riskLevel;
    temporaryStatusesOfFilters = filterData;
    let applyFilter = false;
    if (
      !riskLevelsStatuses?.high ||
      !riskLevelsStatuses?.low ||
      !riskLevelsStatuses?.medium
    ) {
      applyFilter = true;
    }
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.count && type.count > 0 && !type.status) {
          applyFilter = true;
        }
      });
    }
    setIsFilterApplied(applyFilter);
    // setModalFilterVisible(false)
    filterAction(isRiskRankingApplied, fromMap);
    const selectedParentCategory = getSelectedParentCategory();
    if (!selectedParentCategory.includes(selectedFilter)) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
    }
  };
  //sorting articles
  useEffect(() => {
    setFilteredArticles2(sortArticles(filteredArticles2, sortState));
  }, [sortState]);

  useEffect(() => {
    //filtering articles
    setFilteredArticles2(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        filteredArticles
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    filteredArticles,
  ]);

  //filtering for article count
  useEffect(() => {
    setOriginalFilteredArticles(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        originalArticles
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    originalArticles,
  ]);

  useEffect(() => {
    filterArticleTypeCount();
  }, [originalfilteredArticles]);

  const handleGeneratePDF = () => {
    setIsPdfGenerating(true);
    setPDFGenerate(true);
    setTimeout(() => {
      let fullName = capitalizeCase(
        searchSettings?.initialSearchString ??
          feed.searchSettings?.initialSearchString ??
          fullName ??
          "",
        true
      );
      generatePdf(
        openArticleDetail?.status ? "article-content-div" : "content-to-pdf",
        fullName,
        () => {
          setIsPdfGenerating(false);
          setPDFGenerate(false);
        }
      );
    }, 500);
  };

  return (
    <div>
      {showHeader && header()}
      {(saveLoading || loading || loadingData) && (
        <Backdrop
          sx={{
            backgroundColor: "#00000000",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        component="main"
        className={Styles.contactsListContainer}
        style={{
          marginTop: showHeader ? 40 : 0,
        }}
      >
        {/* <FilterHeader
            totalArticle={filteredArticles?.length}
            isEnableFilter={isEnableFilter}
            handlePressFilter={handlePressFilter}
            modalFilterVisible={modalFilterVisible}
            isFilterApplied={isFilterApplied}
          />
      {(!isFilterApplied && filteredArticles.length == 0) && <LoadFeedAnimation
            finishAnimation={animationFinished}
            onFinishAnimation={() => {
              setAnimationFinished(true)
            }}
            style={{marginHorizontal: 10, marginBottom: 20,}}
            articleLoading={false}
            isNoResult={true}
          />} */}
        {originalArticles && isShowPersonalInfo && personalInfo ? (
          <>
            <PersoneInfo
              setOpenMapView={(isOpen) => {
                setOpenMapView(isOpen);
                setOpenMapDashboard(isOpen);
              }}
              profileName={
                searchSettings?.initialSearchString ??
                feed.searchSettings?.initialSearchString ??
                fullName ??
                ""
              }
              linkedInData={linkedInUserData}
              verified={verified}
              setVerified={setVerified}
              partial={partial}
              setPartial={setPartial}
              needsReview={needsReview}
              setNeedsReview={setNeedsReview}
              personalInfo={personalInfo}
              onClick={onArticleTypeClick}
              processArticleInProgress={processArticleInProgress}
              feedbackOptions={feedbackOptions1}
              profilePic={profilePic1}
              potentialMatchId={Variables.potentialMatchId}
              setProfilePic={setProfilePic1}
              setPDFWebFerretAI={setFerretAI}
              setPDFWebFerretAISummary={setFerretAISummary}
              setPDFEducationMore={setEducationMore}
              setPDFExperienceMore={setExperienceMore}
              setPDFShowOthers={setShowOthers}
              setPDFShowMore={setShowMore}
            />
            {isPDFGenerate && (
              <PersoneInfoWeb
                profileName={
                  searchSettings?.initialSearchString ??
                  feed.searchSettings?.initialSearchString ??
                  fullName ??
                  ""
                }
                linkedInData={linkedInUserData}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                personalInfo={personalInfo}
                onClick={onArticleTypeClick}
                processArticleInProgress={processArticleInProgress}
                feedbackOptions={feedbackOptions1}
                profilePic={profilePic1}
                potentialMatchId={Variables.potentialMatchId}
                setProfilePic={setProfilePic1}
                PDFFerretAISummary={ferretAISummary}
                PDFFerretAI={ferretAI}
                educationPDFMore={educationMore}
                experiencePDFMore={experienceMore}
                showPDFOthers={showOthers}
                showPDFMore={showMore}
              />
            )}
          </>
        ) : (
          <SectionListView
            contactDataID={savedContactID}
            selectedParentCategory={getSelectedParentCategory()}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            isShowPersonalInfo={isShowPersonalInfo}
            searchTerm={searchTerm}
            recentSearchId={recentSearchId}
            savedContactID={savedContactID}
            isVisible={modalFilterVisible}
            onFilterCompleted={onFilterCompleted}
            articleType={articleFilterData}
            articleCount={articleTypeCount}
            articleRelevacnyData={JSON.stringify(props)}
            articles={sortArticles(filteredArticles2, sortState)}
            articleSource={articleSource}
            noBtnPressDelay={true}
            isShowName={false}
            contactData={saveSearchVariables?.data}
            reloadScreenData={reloadScreenData}
            configData={configurationData}
            configLoading={ConfigurationLoading}
            sortState={sortState}
            setSortState={setSortState}
            showStrongMatchArticles={showStrongMatchArticles}
            setShowStrongMatchArticles={setShowStrongMatchArticles}
            showWeakMatchArticles={showWeakMatchArticles}
            setShowWeakMatchArticles={setShowWeakMatchArticles}
            filterAttributes={filterAttributes}
            setFilterAttributes={setFilterAttributes}
            readFilter={readFilter}
            setReadFilter={setReadFilter}
            unreadFilter={unreadFilter}
            setUnreadFilter={setUnreadFilter}
            attributes={attributes}
            setAttributes={setAttributes}
            setHeaderTags={setHeaderTags}
            headerTags={headerTags}
            saveRelevancyData={saveRelevancyData}
            setSaveRelevancyData={setSaveRelevancyData}
            deleteRelevancyData={deleteRelevancyData}
            setDeleteRelevancyData={setDeleteRelevancyData}
            setRefreshList={setRefreshList}
            verified={verified}
            setVerified={setVerified}
            partial={partial}
            setPartial={setPartial}
            needsReview={needsReview}
            setNeedsReview={setNeedsReview}
            setShowHeader={setShowHeader}
            feed={feed}
            setArticleDetails={setArticleDetails}
            processingArticle={hasData}
            openArticleDetail={openArticleDetail}
            setOpenArticleDetail={setOpenArticleDetail}
            setPDFIsSummaryExpanded={setIsSummaryExpanded}
            setPDFSectionIndex={setSectionIndex}
            handleGeneratePDF={handleGeneratePDF}
            articleSummary={articleSummary}
            setArticleSummary={setArticleSummary}
            showArticleSummary={showArticleSummary}
            setShowArticleSummary={setShowArticleSummary}
          />
        )}

        {showSaveConfirmationAlert.status && (
          <SaveSearchResultModal
            setShowSaveConfirmationAlert={setShowSaveConfirmationAlert}
            name={titleCase(showSaveConfirmationAlert.msg)}
            cancelBtnClick={backToSearchComponent}
            setChkBxNotifyUser={setChkBxNotifyUser}
            chkBxNotifyUser={chkBxNotifyUser}
            setSaveSearch={setSaveSearch}
            saveSearch={saveSearch}
            setMonitorSearch={setMonitorSearch}
            monitorSearch={monitorSearch}
            saveMySearches={saveMySearches}
            showNotifyUser={hasData}
          />
        )}
      </Box>
      {/* <GraphDate
        riskLevels = {riskLevels}
        filteredArticles ={filteredArticles}
      ></GraphDate> */}
      {showProcessArticlesModal.status && (
        <ProcessingResultModal
          processArticleDone={processArticleDone}
          name={titleCase(
            searchSettings?.initialSearchString ??
              feed.searchSettings?.initialSearchString ??
              fullName ??
              ""
          )}
          showProcessArticlesModal={showProcessArticlesModal}
          setShowProcessArticlesModal={setShowProcessArticlesModal}
          onArticleTypeClick={onArticleTypeClick}
        />
      )}

      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={cancelPopup}
          modelType={ModelType.WATCHED_SEARCH_LIMIT_MODAL}
        />
      )}

      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
      {true && openArticleDetail && openArticleDetail.status && (
        <div
          style={{
            display: isPDFGenerate ? "block" : "none",
            zIndex: -100,
            position: "absolute",
          }}
        >
          <Article
            isPDFSummaryExpanded={isSummaryExpanded}
            sectionPFDIndex={sectionIndex}
            articleSummary={articleSummary}
            showArticleSummary={showArticleSummary}
            {...openArticleDetail.data}
          />
        </div>
      )}
      {originalArticles && isShowPersonalInfo && personalInfo && (
        <div onClick={handleGeneratePDF} className={Styles.flotingCircle}>
          <PDFDownload />
          <Download />
        </div>
      )}
      {openMapView && (
        <MapView
          onFilterCompleted={(filter) => {
            onFilterCompleted(riskLevelsStatuses, filter, false, true);
          }}
          articleFilterData={articleFilterData}
          articles={mapFilteredArticles}
          setOpenMapView={setOpenMapView}
          setOpenMapDashboard={setOpenMapDashboard}
          configData={configurationData}
          configLoading={ConfigurationLoading}
          onArticleTypeClick={onArticleTypeClick}
          reloadScreenData={reloadScreenData}
          articleSource={articleSource}
          searchTerm={searchTerm}
          savedContactID={savedContactID}
          recentSearchId={recentSearchId}
          contactData={saveSearchVariables?.data}
          setRefreshList={setRefreshList}
        />
      )}
    </div>
  );
};

export default SearchResults;
