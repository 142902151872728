import React, { useEffect, useState } from "react";
import Styles from './personeInfo.module.css'
import Markdown from 'react-markdown'

const TypeWriter = (props) => {

  let i = 0;
  var speed = 20; // Adjust the speed as needed
  let timeoutTypeWriter = null

  const { fullTxt, isSpeed = true } = props;
  const [text, setText] = useState("")

  useEffect(() => {
    setText('')
    clearTimeout(timeoutTypeWriter); // Clear any existing timeout
    i = 0; // Reset index
    typeWriter();
    
    return () => {
      clearTimeout(timeoutTypeWriter); // Clear timeout when unmounting
    }
  }, [fullTxt]) // Re-run effect when fullTxt changes

  const typeWriter = () => {
    if (i < fullTxt.length) {
      setText((prevText) => prevText + fullTxt.charAt(i))
      timeoutTypeWriter = setTimeout(typeWriter, speed)
      i++;
    }
  }

  return (
    <Markdown
      className={`${Styles.ferretSummary}`}
      children={text}
    />
  )
}

export default TypeWriter;
