import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import ArticleCardWithQuery from "../articleCardWithQuery/articleCardWithQuery";
import { useLazyQuery, useMutation } from "react-apollo";
import { GET_FULLTEXT, GET_CONFIGURATION } from "../../queries/queries";
import createStyle from "./styles";
import Variables from "../../utils/Variables";
import SaveArticleRelevancy from "./saveArticleRelevancy";
import EmptyCategoryMessage from "../emptyCategoryMessage/emptyCategoryMessage";
import { UPDATE_FERRET_USER } from "../../queries/queries";
import { userInfo, setUserInfo } from "../../root-navigation";
import { UserContext } from "../../contexts/userContext";
import {
  filterChangesCounter,
  filterArticlesByVerificationType,
} from "../../utils/filterUtils";
import FlatList from "flatlist-react";
import { useNavigate } from "react-router-dom";
import FiltersView from "../filtersView/filtersView";
import Article from "../article/article";
import { Backdrop, CircularProgress, Toolbar } from "@mui/material";
import { searchResultStore } from "../../store/searchResultStore";

let lastTimeout = null;
const SectionListView = (props) => {
  const navigate = useNavigate();
  const flatListRef = useRef();
  const scrollDivRef = useRef();
  const [filterHeight, setFilterHeight] = useState(0);
  const filterRef = useRef(null);
  let pathName = window.location.pathname;

  const {
    type = "",
    articles,
    articlesLoading = false,
    onEndReachedHandler,
    onScrollBeginDrag,
    refreshing = false,
    onHandleRefresh,
    articleCount,
    isVisible,
    onFilterCompleted,
    articleType,
    isHeader = false,
    resetFilter = false,
    recentSearchId,
    articleSource = "FEED",
    searchTerm,
    savedContactID,
    noBtnPressDelay = false,
    isSetOldFilter = false,
    isShowName = true,
    contactData,
    contactDataID,
    selectedFilter,
    setSelectedFilter,
    isShowPersonalInfo,
    reloadScreenData,
    configData = [],
    configLoading = false,
    sortState,
    setSortState,
    showStrongMatchArticles,
    setShowStrongMatchArticles,
    showWeakMatchArticles,
    setShowWeakMatchArticles,
    filterAttributes,
    setFilterAttributes,
    readFilter,
    setReadFilter,
    unreadFilter,
    setUnreadFilter,
    attributes,
    setAttributes,
    selectedParentCategory,
    setHeaderTags,
    headerTags,
    saveRelevancyData,
    setSaveRelevancyData,
    deleteRelevancyData,
    setDeleteRelevancyData,
    setShowHeader,
    feed,
    setRefreshList,
    verified,
    setVerified,
    partial,
    setPartial,
    needsReview,
    setNeedsReview,
    recentArticle,
    setArticleDetails,
    processingArticle,
    openArticleDetail,
    setOpenArticleDetail,
    savedSearchProps,
    setPDFIsSummaryExpanded,
    setPDFSectionIndex,
    articleSummary,
    setArticleSummary,
    showArticleSummary,
    setShowArticleSummary,
  } = props;

  const selectedMapArticle = searchResultStore(
    (state) => state.selectedMapArticle
  );
  const setSelectedMapArticle = searchResultStore(
    (state) => state.setSelectedMapArticle
  );

  const { searchList, articleDetail, searchListContacts } = feed ?? {};

  const style = createStyle();
  const [filterChangesCount, setFilterChangesCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedDocument, setSelectedDocument] = useState();
  const [riskLevels, setRiskLevels] = useState(
    getEmptyRiskLevels(articleCount)
  );
  const [riskLevelsStatuses, setRiskLevelsStatuses] = useState(
    isSetOldFilter
      ? Variables.riskLevelsStatuses
      : {
          high: true,
          medium: true,
          low: true,
        }
  );
  const [showLoader, setShowLoader] = useState(false);
  const [noResultFound, setNoresultfound] = useState(false);
  const [openFilterVisible, setOpenFilterVisible] = useState(false);

  const { relevantArticle } = SaveArticleRelevancy(
    reloadScreenData,
    setShowLoader,
    articleSource,
    searchTerm,
    savedContactID,
    recentSearchId,
    contactData,
    setRefreshList
  );

  const [filteredArticles, setFilteredArticles] = useState(articles);

  const [verifiedCount, setVerifiedCount] = useState(0);
  const [needsReviewCount, setNeedsReviewCount] = useState(0);
  const [partialCount, setPartialCount] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const { setUser } = useContext(UserContext);
  const [userUpdaterMutation, { loading: loadingDontShow }] = useMutation(
    UPDATE_FERRET_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ updateUser }) => {
        let u = setUserInfo(updateUser);
        setUser({ ...u });
      },
    }
  );

  const [
    getFulltext,
    { loading: loadingGetFullText, data: dataGetFullText },
  ] = useLazyQuery(GET_FULLTEXT, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      pathName == "/savedContactsArticlesDetails" &&
      !openArticleDetail.status
    ) {
      navigate(-1, { replace: true });
    }
  }, []);

  useEffect(() => {
    scrollDivRef?.current?.scrollTo(0, 0);
  }, [selectedFilter]);

  useEffect(() => {
    if (openArticleDetail.status == false) {
      let scrollTop = scrollPosition;

      setTimeout(() => {
        scrollDivRef?.current?.scrollTo({
          top: scrollTop,
        });
      }, 500);
    }
  }, [openArticleDetail.status]);

  useEffect(() => {
    if (props?.navFrom == "recentActivity") {
      let tempArticles = articles;
      tempArticles.map((a) => {
        a.data = filterArticlesByVerificationType(
          a?.data,
          !verified,
          !partial,
          !needsReview
        );
      });
      tempArticles = tempArticles.filter((item) => item?.data?.length > 0);
      setFilteredArticles(tempArticles);
    } else {
      setFilteredArticles(
        filterArticlesByVerificationType(
          articles,
          !verified,
          !partial,
          !needsReview
        )
      );
    }
  }, [articles, verified, partial, needsReview]);

  useEffect(() => {
    Variables.riskLevelsStatuses = isSetOldFilter
      ? Variables.riskLevelsStatuses
      : {
          high: true,
          medium: true,
          low: true,
        };
  }, []);

  useEffect(() => {
    if (selectedMapArticle) {
      setScrollPosition(scrollDivRef?.current?.scrollTop);
      setSelectedItem(selectedMapArticle);
      if (!!selectedMapArticle.articleId) {
        callGetFullText(selectedMapArticle, selectedMapArticle.selected);
      } else {
        gotoArticle(
          selectedMapArticle,
          selectedMapArticle,
          selectedMapArticle.selected
        );
      }
    }
    setSelectedMapArticle(undefined);
  }, [selectedMapArticle]);

  useEffect(() => {
    if (filteredArticles?.length < 1 && !articlesLoading) {
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
      lastTimeout = setTimeout(() => {
        setNoresultfound(true);
      }, 500);
    } else {
      clearTimeout(lastTimeout);
      setNoresultfound(false);
    }
  }, [filteredArticles, articlesLoading]);

  useEffect(() => {
    Variables.statusesOfFilters = isSetOldFilter
      ? Variables.riskLevelsStatuses
      : null;
  }, []);

  useEffect(() => {
    const newRiskLevels = parseDataForRiskBar(riskLevelsStatuses, articleCount);
    setRiskLevels(newRiskLevels);
  }, [articleCount, riskLevelsStatuses]);

  useEffect(() => {
    // if(isVisible && flatListRef && articles && articles.length){
    //   if(flatListRef.current.scrollToIndex)
    //     flatListRef.current.scrollToIndex({ index: 0 });
    //   else{
    //     flatListRef.current.scrollToLocation({
    //       animated: true,
    //       itemIndex: 0,
    //       sectionIndex: 0,
    //       viewPosition: 0
    //   })
    //   }
    // }
  }, [isVisible]);

  useEffect(() => {
    //filter change counter
    setFilterChangesCount(
      filterChangesCounter(
        showStrongMatchArticles,
        showWeakMatchArticles,
        sortState,
        readFilter,
        unreadFilter,
        filterAttributes,
        selectedParentCategory
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    articles,
  ]);

  useEffect(() => {
    if (articleType) {
      Variables.riskLevelsStatuses = riskLevelsStatuses;
      onFilterCompleted(riskLevelsStatuses, Variables.statusesOfFilters, true);
    }
  }, [riskLevelsStatuses]);

  useEffect(() => {
    var res;
    if (props?.navFrom == "recentActivity") {
      res = recentArticle.reduce(function (obj, v) {
        obj[v.verificationType] = (obj[v.verificationType] || 0) + 1;
        return obj;
      }, {});
    } else {
      res = articles.reduce(function (obj, v) {
        obj[v.verificationType] = (obj[v.verificationType] || 0) + 1;
        return obj;
      }, {});
    }

    if (res.hasOwnProperty("verified")) setVerifiedCount(res["verified"]);
    else setVerifiedCount(0);
    if (res.hasOwnProperty("needs_review"))
      setNeedsReviewCount(res["needs_review"]);
    else setNeedsReviewCount(0);
    if (res.hasOwnProperty("partial")) setPartialCount(res["partial"]);
    else setPartialCount(0);
  }, [articles]);

  useEffect(() => {
    if (
      props?.navFrom == "recentActivity" &&
      verifiedCount == 0 &&
      needsReviewCount == 0 &&
      partialCount == 0
    ) {
      setNoresultfound(true);
    } else {
      setNoresultfound(false);
    }
  }, [verifiedCount, needsReviewCount, partialCount]);

  const onPress = (riskLevel) => {
    setRiskLevelsStatuses((riskLevelsStatuses) => {
      if (!Variables.riskSelected) {
        riskLevelsStatuses["high"] = false;
        riskLevelsStatuses["medium"] = false;
        riskLevelsStatuses["low"] = false;
        Variables.riskSelected = true;
      }
      riskLevelsStatuses[riskLevel] = !riskLevelsStatuses[riskLevel];
      return {
        ...riskLevelsStatuses,
      };
    });
  };

  const openFilter = (value) => {
    setOpenFilterVisible(value);
  };

  const dontShow = () => {
    const requestData = {
      relevancySortFilterTooltip: true,
    };
    userUpdaterMutation({
      variables: requestData,
    });
  };

  const callGetFullText = (item, selected) => {
    setSelectedDocument(selected);
    let data = {};
    if (item.articleType == "CL RECAP") {
      data = {
        articleId: selected.details.articleId,
        dataSource: "CL_RECAP",
      };
    } else {
      data = {
        articleId: item.articleId,
        dataSource: "OTHER",
      };
    }
    getFulltext({
      variables: data,
    });
  };

  useEffect(() => {
    if (dataGetFullText) {
      const newArticle = {
        ...selectedItem,
        article: {
          ...selectedItem?.article,
          fulltext: dataGetFullText.getFulltxt.data || "",
        },
      };
      gotoArticle(newArticle, selectedItem, selectedDocument);
    }
  }, [dataGetFullText, loadingGetFullText]);

  const gotoArticle = (item, selectedItem, selectedDocument) => {
    let data = {
      isRecent: isHeader,
      articleCount: props.articleCount,
      newsItem: item,
      article: item.article,
      selectedDocument,
      selectedItem,
      recentSearchId,
      articleSource,
      searchTerm,
      savedContactID,
      type: type,
      contactData: contactData,
      setRelevantArticle: relevantArticle,
      setShowLoader: setShowLoader,
      openFilter: openFilter,
      dontShow: dontShow,
      setHeaderTags: setHeaderTags,
      headerTags: headerTags,
      saveRelevancyData: saveRelevancyData,
      setSaveRelevancyData: setSaveRelevancyData,
      deleteRelevancyData: deleteRelevancyData,
      setDeleteRelevancyData: setDeleteRelevancyData,
      feed: feed,
      setShowHeader: setShowHeader,
      setOpenArticleDetail: setOpenArticleDetail,
      setArticleDetails: setArticleDetails,
      setRefreshList: setRefreshList,
    };
    setArticleDetails?.(true);
    setShowHeader?.(false);
    // window.scrollTo(0, 0);
    setOpenArticleDetail({ status: true, data: data });
    if (!isHeader) {
      if (searchListContacts) {
        navigate("/savedContactsArticlesDetails", {
          state: JSON.stringify({
            ...savedSearchProps,
            searchList: true,
            articleDetail: true,
          }),
        });
      } else
        navigate("/SearchResultsArticle", {
          state: { ...props.feed, searchList: true, articleDetail: true },
        });
    }
  };

  const onEndReached = () => {
    onEndReachedHandler?.();
  };
  return (
    <>
      {openArticleDetail.status && (articleDetail || isHeader) ? (
        <Article
          openArticleDetail={openArticleDetail}
          setOpenArticleDetail={setOpenArticleDetail}
          setPDFIsSummaryExpanded={setPDFIsSummaryExpanded}
          setPDFSectionIndex={setPDFSectionIndex}
          handleGeneratePDF={props.handleGeneratePDF}
          articleSummary={articleSummary}
          setArticleSummary={setArticleSummary}
          showArticleSummary={showArticleSummary}
          setShowArticleSummary={setShowArticleSummary}
          {...openArticleDetail.data}
        />
      ) : (
        <div>
          {isHeader ? (
            <>
              <FiltersView
                openFilterVisible={openFilterVisible}
                selectedParentCategory={selectedParentCategory}
                isAllShow={isHeader}
                isShowPersonalInfo={isShowPersonalInfo}
                noBtnPressDelay={noBtnPressDelay}
                onPress={onPress}
                riskLevels={riskLevels}
                isVisible={isVisible}
                statusesOfFiltersOld={
                  isSetOldFilter ? Variables.statusesOfFilters : null
                }
                resetFilter={resetFilter}
                onFilterCompleted={(filter) => {
                  Variables.statusesOfFilters = filter;
                  onFilterCompleted(riskLevelsStatuses, filter);
                }}
                articleType={articleType}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                sortState={sortState}
                setSortState={setSortState}
                showStrongMatchArticles={showStrongMatchArticles}
                setShowStrongMatchArticles={setShowStrongMatchArticles}
                showWeakMatchArticles={showWeakMatchArticles}
                setShowWeakMatchArticles={setShowWeakMatchArticles}
                filterAttributes={filterAttributes}
                setFilterAttributes={setFilterAttributes}
                readFilter={readFilter}
                setReadFilter={setReadFilter}
                unreadFilter={unreadFilter}
                setUnreadFilter={setUnreadFilter}
                filterChangesCount={filterChangesCount}
                attributes={attributes}
                verifiedCount={verifiedCount}
                needsReviewCount={needsReviewCount}
                partialCount={partialCount}
                setAttributes={setAttributes}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                processingArticle={processingArticle}
                filterRef={filterRef}
                setFilterHeight={setFilterHeight}
                navFrom={props?.navFrom}
              />
              <div style={{ marginTop: 50 }}>
                {noResultFound ? (
                  <div style={{ paddingTop: 120 }}>
                    <EmptyCategoryMessage />
                  </div>
                ) : (
                  <li
                    style={{
                      paddingBottom: 100,
                      paddingTop: filterHeight + 10,
                      zIndex: -1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {filteredArticles &&
                      Object.entries(filteredArticles).map(([key, values]) => (
                        <>
                          <div style={style.sectionView}>
                            <span style={style.contactSectionListTitle}>
                              {values.title}
                            </span>
                          </div>
                          {values?.data &&
                            values.data.map((item, index) => (
                              <ArticleCardWithQuery
                                key={index + "_" + item.articleId}
                                contactData={contactData}
                                recentSearchId={recentSearchId}
                                contactDataID={contactDataID}
                                openFilter={openFilter}
                                dontShow={dontShow}
                                isRecent={true}
                                newsItem={item}
                                isShowName={isShowName}
                                {...props}
                                configData={configData}
                                configLoading={configLoading}
                                onPress={(selected) => {
                                  setSelectedItem(item);
                                  if (!!item.articleId) {
                                    callGetFullText(item, selected);
                                  } else {
                                    gotoArticle(item, item, selected);
                                  }
                                }}
                                setRelevantArticle={relevantArticle}
                                setShowLoader={setShowLoader}
                                setHeaderTags={setHeaderTags}
                                headerTags={headerTags}
                                saveRelevancyData={saveRelevancyData}
                                setSaveRelevancyData={setSaveRelevancyData}
                                deleteRelevancyData={deleteRelevancyData}
                                setDeleteRelevancyData={setDeleteRelevancyData}
                                filterAttributes={filterAttributes}
                                setRefreshList={setRefreshList}
                              />
                            ))}
                        </>
                      ))}
                  </li>
                )}
              </div>
            </>
          ) : (
            <>
              <Toolbar />
              <FiltersView
                openFilterVisible={openFilterVisible}
                selectedParentCategory={selectedParentCategory}
                isAllShow={isHeader}
                isShowPersonalInfo={isShowPersonalInfo}
                noBtnPressDelay={noBtnPressDelay}
                riskLevels={riskLevels}
                onPress={onPress}
                isVisible={isVisible}
                resetFilter={resetFilter}
                statusesOfFiltersOld={
                  isSetOldFilter ? Variables.statusesOfFilters : null
                }
                onFilterCompleted={(filter) => {
                  Variables.statusesOfFilters = filter;
                  onFilterCompleted(riskLevelsStatuses, filter);
                }}
                articleType={articleType}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                sortState={sortState}
                setSortState={setSortState}
                showStrongMatchArticles={showStrongMatchArticles}
                setShowStrongMatchArticles={setShowStrongMatchArticles}
                showWeakMatchArticles={showWeakMatchArticles}
                setShowWeakMatchArticles={setShowWeakMatchArticles}
                filterAttributes={filterAttributes}
                setFilterAttributes={setFilterAttributes}
                readFilter={readFilter}
                setReadFilter={setReadFilter}
                unreadFilter={unreadFilter}
                setUnreadFilter={setUnreadFilter}
                filterChangesCount={filterChangesCount}
                attributes={attributes}
                setAttributes={setAttributes}
                verifiedCount={verifiedCount}
                needsReviewCount={needsReviewCount}
                partialCount={partialCount}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                processingArticle={processingArticle}
                filterRef={filterRef}
                setFilterHeight={setFilterHeight}
                navFrom={props?.navFrom}
              />

              <div
                ref={scrollDivRef}
                style={{
                  marginTop: filterHeight,
                  height: window.innerHeight - filterHeight - 100,
                  overflow: "auto",
                  position: "fixed",
                  paddingBottom: 10,
                  width: "100%",
                }}
              >
                {noResultFound ? (
                  <EmptyCategoryMessage />
                ) : filteredArticles?.length == 0 ? (
                  <EmptyCategoryMessage />
                ) : (
                  filteredArticles?.map((item, index) => {
                    return (
                      <ArticleCardWithQuery
                        key={index + "_" + item.articleId}
                        contactData={contactData}
                        recentSearchId={recentSearchId}
                        contactDataID={contactDataID}
                        openFilter={openFilter}
                        dontShow={dontShow}
                        newsItem={item}
                        isShowName={isShowName}
                        {...props}
                        configData={configData}
                        configLoading={configLoading}
                        onPress={(selected) => {
                          setScrollPosition(scrollDivRef?.current?.scrollTop);
                          setSelectedItem(item);
                          if (!!item.articleId) {
                            callGetFullText(item, selected);
                          } else {
                            gotoArticle(item, item, selected);
                          }
                        }}
                        setRelevantArticle={relevantArticle}
                        setShowLoader={setShowLoader}
                        setHeaderTags={setHeaderTags}
                        headerTags={headerTags}
                        saveRelevancyData={saveRelevancyData}
                        setSaveRelevancyData={setSaveRelevancyData}
                        deleteRelevancyData={deleteRelevancyData}
                        setDeleteRelevancyData={setDeleteRelevancyData}
                        setRefreshList={setRefreshList}
                      />
                    );
                  })
                )}
              </div>
            </>
          )}
          {(showLoader || loadingDontShow || loadingGetFullText) && (
            <Backdrop
              sx={{
                backgroundColor: "#00000000",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "#00000000",
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
      )}
    </>
  );
};

const parseDataForRiskBar = (riskLevels, articleCount) => {
  const newRiskLevels = getEmptyRiskLevels(articleCount);
  Object.keys(newRiskLevels).forEach(
    (riskLevelKey) =>
      (newRiskLevels[riskLevelKey].isSelected = riskLevels[riskLevelKey])
  );
  return newRiskLevels;
};

const getEmptyRiskLevels = (articleCount) => {
  const riskColors = {
    high: "#DA011B",
    medium: "#FB8100",
    low: "#0F9125",
    HIGH: "#FF3D3D",
    MEDIUM: "#FFAD57",
    LOW: "#56DC6D",
    null: "#56DC6D",
  };
  const baseRiskLevel = {
    badgeCounter: 0,
    isSelected: true,
  };
  return {
    high: {
      ...baseRiskLevel,
      amount: articleCount?.highRisk ? articleCount.highRisk : 0,
      title: "High",
      color: riskColors?.high,
    },
    medium: {
      ...baseRiskLevel,
      amount: articleCount?.alerts ? articleCount.alerts : 0,
      title: "Medium",
      color: riskColors?.medium,
    },
    low: {
      ...baseRiskLevel,
      amount: articleCount?.news ? articleCount.news : 0,
      title: "Low",
      color: riskColors?.low,
    },
  };
};

export default SectionListView;
